<template>
  <div>
    <update-client
      v-if="dialogs.updateClient"
      @close="dialogs.updateClient = false"
      :item="this.item"
    />
    <v-icon small class="mr-2" @click="dialogs.updateClient = true">
      mdi-pencil
    </v-icon>
  </div>
</template>

<script>
import UpdateClient from "./dialogs/UpdateClient";

export default {
  data() {
    return {
      dialogs: {
        updateClient: false,
      },
    };
  },
  props: ["item"],
  components: {
    UpdateClient,
  },
};
</script>

<style>
</style>
