<template>
  <td :colspan="headers.length">
    <div v-if="!isCanceled">
      <v-list-item>
        <v-list-item-subtitle class="text-wrap">
          <span class="text--primary">Situação do pagamento:</span>
          {{ item.payment_status.name }}
        </v-list-item-subtitle>
      </v-list-item>
      <v-divider></v-divider>
      <div v-if="paymentDate">
        <v-list-item>
          <v-list-item-subtitle class="text-wrap">
            <span class="text--primary">Data do pagamento:</span>
            {{ paymentDate }}
          </v-list-item-subtitle>
        </v-list-item>
        <v-divider></v-divider>
      </div>
    </div>
    <v-list-item>
      <v-list-item-subtitle class="text-wrap">
        <span class="text--primary">Situação da locação:</span>
        {{ item.rent_status.name }}
      </v-list-item-subtitle>
    </v-list-item>
    <div v-if="item.cancel_reason">
      <v-divider></v-divider>
      <v-list-item>
        <v-list-item-subtitle>
          <span class="text--primary text-wrap">Motivo do cancelamento:</span>
          {{ item.cancel_reason }}
        </v-list-item-subtitle>
      </v-list-item>
    </div>
    <div v-if="item.dumpster">
      <v-divider></v-divider>
      <v-list-item>
        <v-list-item-subtitle>
          <span class="text--primary">Caçamba:</span>
          {{ item.dumpster.number }}
          {{ item.dumpster.volume ? ` (${item.dumpster.volume}m³)` : "" }}
        </v-list-item-subtitle>
      </v-list-item>
    </div>
    <div v-if="item.client.cell">
      <v-divider></v-divider>
      <v-list-item>
        <v-list-item-subtitle>
          <span class="text--primary">Celular:</span> {{ item.client.cell }}
        </v-list-item-subtitle>
      </v-list-item>
    </div>
  </td>
</template>

<script>
import status from "@/mixins/rent/status";
import payment from "@/mixins/rent/payment";
import moment from "moment-timezone";

export default {
  props: ["item", "headers"],
  mixins: [status, payment],
  computed: {
    isCanceled() {
      return this.canceled(this.item);
    },
    paymentDate() {
      if (this.paid(this.item) && this.item.payment_date) {
        return moment(this.item.payment_date, "YYYY-MM-DD HH:mm:ss").format(
          "DD/MM/YYYY"
        );
      }
    },
  },
};
</script>

<style>
</style>
