<template>
  <v-progress-linear
    :active="$store.state.loading.show"
    indeterminate
    absolute
    top
    color="white"
  ></v-progress-linear>
</template>

<script>
export default {};
</script>

<style scoped>
</style>
