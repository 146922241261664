var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-tooltip',{attrs:{"right":"","disabled":_vm.isCanceled},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({class:{
        'text--disabled text-decoration-line-through': _vm.isCanceled,
        'text--disabled': _vm.waitingPaiment,
        'text--primary': _vm.paid,
      }},'span',attrs,false),on),[_vm._v(_vm._s(_vm.price))])]}}])},[_c('span',[_vm._v(" "+_vm._s(this.item.payment_status.name))])])}
var staticRenderFns = []

export { render, staticRenderFns }