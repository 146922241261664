<template>
  <div>
    <v-expansion-panels flat v-model="panel" tile>
      <v-expansion-panel>
        <v-expansion-panel-header color="grey lighten-3">
          <div>
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-icon dark color="grey darken-1" v-bind="attrs" v-on="on">
                  mdi-filter
                </v-icon>
              </template>
              <span>Filtrar locações</span>
            </v-tooltip>
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-container fluid>
            <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
              <v-row>
                <v-col cols="12" md="4">
                  <ValidationProvider
                    name="número da locação"
                    rules="integer"
                    v-slot="{ errors }"
                  >
                    <v-combobox
                      multiple
                      v-model="publicId"
                      disable-lookup
                      label="Número da locação"
                      append-icon
                      small-chips
                      hint="Números separados por vírgula permitem filtrar mais de uma locação"
                      @change="handleSubmit(filter)"
                      clearable
                      :delimiters="[',']"
                      :error-messages="errors"
                      deletable-chips
                    >
                    </v-combobox>
                  </ValidationProvider>
                </v-col>
                <v-col cols="12" md="4">
                  <v-select
                    multiple
                    :items="rentStatuses"
                    item-value="id"
                    item-text="name"
                    chips
                    small-chips
                    clearable
                    label="Situação da locação"
                    @change="handleSubmit(filter)"
                    v-model="rentStatusId"
                  ></v-select>
                </v-col>
                <v-col cols="12" md="4">
                  <v-select
                    :items="paymentStatuses"
                    item-value="id"
                    item-text="name"
                    @change="handleSubmit(filter)"
                    small-chips
                    clearable
                    label="Situação do pagamento"
                    v-model="paymentStatusId"
                  ></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="4">
                  <ValidationProvider
                    name="número da caçamba"
                    rules="integer"
                    v-slot="{ errors }"
                  >
                    <v-combobox
                      multiple
                      v-model="dumpsterNumber"
                      disable-lookup
                      @change="handleSubmit(filter)"
                      label="Número da caçamba"
                      append-icon
                      small-chips
                      hint="Números separados por vírgula permitem filtrar mais de uma caçamba"
                      clearable
                      :delimiters="[',']"
                      :error-messages="errors"
                      deletable-chips
                    >
                    </v-combobox>
                  </ValidationProvider>
                </v-col>
                <v-col cols="12" md="4">
                  <v-autocomplete
                    v-model="clientByClientName"
                    @change="filter"
                    :items="searchByClientNameItems"
                    :loading="isLoadingByClientName"
                    :search-input.sync="searchRentsByClientName"
                    small-chips
                    clearable
                    hide-details
                    return-object
                    itemid="client.id"
                    item-text="client.name"
                    item-value="client.id"
                    label="Nome do cliente"
                    no-data-text="Nenhum dado encontrado"
                  >
                    <template v-slot:item="{ item }">
                      <v-list-item-content>
                        <v-list-item-title
                          v-text="item.client.name"
                        ></v-list-item-title>
                        <v-list-item-subtitle
                          v-text="parseAddress(item)"
                        ></v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" md="4">
                  <v-autocomplete
                    v-model="clientByStreetName"
                    @change="filter"
                    :items="searchByStreetNameItems"
                    :loading="isLoadingByStreetName"
                    :search-input.sync="searchRentsByStreetName"
                    small-chips
                    clearable
                    hide-details
                    return-object
                    itemid="street_name"
                    item-text="street_name"
                    item-value="street_name"
                    label="Endereço da locação"
                    no-data-text="Nenhum dado encontrado"
                  >
                    <template v-slot:item="{ item }">
                      <v-list-item-content>
                        <v-list-item-title
                          v-text="item.street_name"
                        ></v-list-item-title>
                      </v-list-item-content>
                    </template>
                  </v-autocomplete>
                </v-col>
              </v-row>
            </ValidationObserver>
          </v-container>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import payment from "@/mixins/rent/payment";
import status from "@/mixins/rent/status";
import filters from "@/mixins/rent/filters";
import address from "@/mixins/rent/address";

export default {
  mixins: [payment, status, filters, address],
  data() {
    return {
      paymentStatusId: parseInt(this.$route.query.paymentStatusId) || null,
      clientByClientName: this.getClientByClientName(),
      clientByStreetName: this.getClientByStreetName(),
      searchByClientNameItems: this.getItemsByClientName(),
      searchByStreetNameItems: this.getItemsByStreetName(),
      rentStatusId: this.getRentStatusId(),
      dumpsterNumber: this.getDumpsterNumber(),
      publicId: this.getPublicIdNumber(),
      singleExpand: true,
      panel: 0,
      paymentStatuses: this.paymentStatusesList(),
      rentStatuses: this.rentStatusesList(),
      isLoadingByClientName: false,
      isLoadingByStreetName: false,
      searchRentsByClientName: null,
      searchRentsByStreetName: null,
      lastChangeByClientName: 0,
      lastChangeByStreetName: 0,
      delayByClientName: 300,
      delayByStreetName: 300,
    };
  },
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  watch: {
    searchRentsByStreetName(val) {
      if (val == null) return;
      if (val.length < 4) return;

      this.isLoadingByStreetName = true;
      this.lastChangeByStreetName = new Date().getTime();
      setTimeout(() => {
        if (
          new Date().getTime() >=
          this.lastChangeByStreetName + this.delayByStreetName
        ) {
          this.$store
            .dispatch("searchRentsByStreetName", { streetName: val })
            .then(() => {
              this.isLoadingByStreetName = false;
              this.searchByStreetNameItems =
                this.$store.getters.rentsByStreetName;
            })
            .catch(() => {
              this.isLoadingByStreetName = false;
            });
        }
      }, this.delayByClientName);
    },
    searchRentsByClientName(val) {
      if (val == null) return;
      if (val.length < 3) return;

      this.isLoadingByClientName = true;
      this.lastChangeByClientName = new Date().getTime();
      setTimeout(() => {
        if (
          new Date().getTime() >=
          this.lastChangeByClientName + this.delayByClientName
        ) {
          this.$store
            .dispatch("searchRentsByClientName", { clientName: val })
            .then(() => {
              this.isLoadingByClientName = false;
              this.searchByClientNameItems =
                this.$store.getters.rentsByClientName;
            })
            .catch(() => {
              this.isLoadingByClientName = false;
            });
        }
      }, this.delayByClientName);
    },
  },
  methods: {
    isSearchingByClientName() {
      return this.$route.query.clientName && this.$route.query.clientId;
    },
    parseSearchByClientNameFromRoute() {
      return {
        client: {
          id: this.$route.query.clientId,
          name: this.$route.query.clientName,
        },
      };
    },
    parseSearchByStreetNameFromRoute() {
      return {
        street_name: this.$route.query.streetName,
      };
    },
    getItemsByStreetName() {
      if (this.$route.query.streetName)
        return [this.parseSearchByStreetNameFromRoute()];

      return this.$store.getters.rentsByStreetName;
    },
    getItemsByClientName() {
      if (this.isSearchingByClientName())
        return [this.parseSearchByClientNameFromRoute()];

      return this.$store.getters.rentsByClientName;
    },
    getClientByStreetName() {
      if (this.$route.query.streetName)
        return this.parseSearchByStreetNameFromRoute();

      return null;
    },
    getClientByClientName() {
      if (this.isSearchingByClientName())
        return this.parseSearchByClientNameFromRoute();

      return null;
    },
    getRentStatusId() {
      return this.parseListToInt(
        this.parseAttributeFromRoute(this.$route, "rentStatusId")
      );
    },
    getRentStatusId() {
      return this.parseListToInt(
        this.parseAttributeFromRoute(this.$route, "rentStatusId")
      );
    },
    getDumpsterNumber() {
      return this.parseAttributeFromRoute(this.$route, "dumpsterNumber");
    },
    getPublicIdNumber() {
      return this.parseAttributeFromRoute(this.$route, "publicId");
    },
    async filter() {
      const isValid = await this.$refs.observer.validate();

      if (isValid) {
        const query = {
          ...this.$route.query,
          page: 1,
          publicId: [this.publicId].join() || undefined,
          rentStatusId: [this.rentStatusId].join() || undefined,
          dumpsterNumber: [this.dumpsterNumber].join() || undefined,
          paymentStatusId: this.paymentStatusId || undefined,
          clientId: this.clientByClientName?.client?.id || undefined,
          clientName: this.clientByClientName?.client?.name || undefined,
          streetName: this.clientByStreetName?.street_name || undefined,
        };

        this.$router.push({ query }, () => {});

        this.$store.dispatch("getRents", {
          pageNumber: 1,
          publicId: this.publicId,
          dumpsterNumber: this.dumpsterNumber,
          paymentStatusId: this.paymentStatusId,
          rentStatusId: this.rentStatusId,
          clientId: this.clientByClientName?.client?.id || null,
          clientName: this.clientByClientName?.client?.name || null,
          streetName: this.clientByStreetName?.street_name || null,
        });
      }
    },
  },
};
</script>

<style>
</style>
