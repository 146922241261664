<template>
  <div>
    <create-dumpster
      v-if="dialogs.createDumpster"
      @close="dialogs.createDumpster = false"
    />
    
    <v-data-table
      :headers="headers"
      :header-props="headerProps"
      :items="dumpsters"
      :page.sync="page"
      :items-per-page="itemsPerPage"
      hide-default-footer
      item-key="id"
      no-data-text="Nenhum dado encontrado"
    >
      <template v-slot:top>
        <dumpster-filter />
        <v-toolbar flat>
          <v-btn
            color="pink"
            fab
            x-small
            dark
            @click="dialogs.createDumpster = true"
          >
            <v-icon dark> mdi-plus </v-icon>
          </v-btn>
        </v-toolbar>
      </template>

      <template v-slot:item.address="{ item }">
        <dumpster-address :item="item" />
      </template>

      <template v-slot:item.actions="{ item }">
        <dumpster-menu :item="item" />
      </template>
    </v-data-table>
    <div class="text-center">
      <v-divider></v-divider>
      <v-container>
        <v-row justify="center">
          <v-col>
            <v-container class="max-width">
              <v-pagination
                v-model="page"
                :length="pageCount"
                :total-visible="7"
              ></v-pagination>
            </v-container>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import DumpsterFilter from "./DumpsterFilter";
import CreateDumpster from "./dialogs/CreateDumpster";
import DumpsterMenu from "./DumpsterMenu";
import filters from "@/mixins/rent/filters";

export default {
  mixins: [filters],
  data() {
    return {
      dialogs: {
        createDumpster: false,
      },
      page: parseInt(this.$route.query.page) || 1,
      headerProps: {
        sortByText: "Ordenar por",
      },
      headers: [
        { text: "Nº", value: "number", sortable: false },
        { text: "Volume m³", value: "volume", sortable: false },
        { value: "actions", sortable: false },
      ],
    };
  },
  components: {
    DumpsterFilter,
    CreateDumpster,
    DumpsterMenu,
  },
  watch: {
    "$route.path": {
      immediate: true,
      handler(to, from) {
        this.$store.commit("resetDumpsters");
      },
    },
    "$route.query.page": function (value) {
      const number = parseInt(value);
      if (!isNaN(number)) {
        this.page = number;
      }
    },
    page(value) {
      const query = { ...this.$route.query, page: value };
      this.$router.replace({ query }, () => {});
      this.getDumpsters(value);
    },
  },
  computed: {
    ...mapGetters({
      totalPages: "paginationTotalPagesDumpsters",
      dumpsters: "allDumpsters",
      itemsPerPage: "paginationPerPageDumpsters",
      pageCount: "paginationTotalPagesDumpsters",
    }),
  },
  methods: {
    getDumpsters(page) {
      this.$store.dispatch("getDumpsters", {
        pageNumber: page,
        dumpsterNumber: this.parseAttributeFromRoute(
          this.$route,
          "dumpsterNumber"
        ),
      });
    },
  },
  mounted() {
    this.getDumpsters(this.page);
  },
};
</script>

<style>
</style>
