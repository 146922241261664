var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-expansion-panels',{attrs:{"flat":"","tile":""},model:{value:(_vm.panel),callback:function ($$v) {_vm.panel=$$v},expression:"panel"}},[_c('v-expansion-panel',[_c('v-expansion-panel-header',{attrs:{"color":"grey lighten-3"}},[_c('div',[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"dark":"","color":"grey darken-1"}},'v-icon',attrs,false),on),[_vm._v(" mdi-filter ")])]}}])},[_c('span',[_vm._v("Filtrar caçambas")])])],1)]),_c('v-expansion-panel-content',[_c('v-container',{attrs:{"fluid":""}},[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"número da caçamba","rules":"integer"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-combobox',{attrs:{"multiple":"","disable-lookup":"","label":"Número da caçamba","append-icon":"","small-chips":"","hint":"Números separados por vírgula permitem filtrar mais de uma caçamba","clearable":"","delimiters":[','],"error-messages":errors,"deletable-chips":""},on:{"change":function($event){return handleSubmit(_vm.filter)}},model:{value:(_vm.dumpsterNumber),callback:function ($$v) {_vm.dumpsterNumber=$$v},expression:"dumpsterNumber"}})]}}],null,true)})],1)],1)]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }