var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var validated = ref.validated;
var handleSubmit = ref.handleSubmit;
return [_c('v-main',[_c('v-container',{attrs:{"fluid":""}},[_c('v-layout',{attrs:{"align-center":"","justify-center":""}},[_c('v-flex',{attrs:{"xs12":"","sm8":"","md4":""}},[_c('v-card',[_c('v-toolbar',{attrs:{"dark":"","color":"primary"}},[_c('v-toolbar-title',[_vm._v(" Redefinir senha ")])],1),_c('v-card-text',[_c('v-form',[_c('ValidationProvider',{attrs:{"name":"senha","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{attrs:{"id":"password","prepend-icon":"mdi-lock","name":"password","label":"Senha","type":"password","error-messages":errors,"success":valid},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return handleSubmit(_vm.submit)}},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"repetir senha","rules":"required|confirmed:senha"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{attrs:{"id":"password_confirmation","prepend-icon":"mdi-lock","name":"password_confirmation","label":"Repertir senha","type":"password","error-messages":errors,"success":valid},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return handleSubmit(_vm.submit)}},model:{value:(_vm.passwordConfirmation),callback:function ($$v) {_vm.passwordConfirmation=$$v},expression:"passwordConfirmation"}})]}}],null,true)})],1)],1),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"primary","block":"","disabled":invalid || !validated},on:{"click":function($event){return handleSubmit(_vm.submit)}}},[_vm._v("Redefinir")])],1)],1)],1)],1)],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }