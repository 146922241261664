<template>
  <list-rents />
</template>

<script>
import ListRents from "../components/rent/ListRents";

export default {
  components: {
    ListRents,
  },
};
</script>
