var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-expansion-panels',{attrs:{"flat":"","tile":""},model:{value:(_vm.panel),callback:function ($$v) {_vm.panel=$$v},expression:"panel"}},[_c('v-expansion-panel',[_c('v-expansion-panel-header',{attrs:{"color":"grey lighten-3"}},[_c('div',[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"dark":"","color":"grey darken-1"}},'v-icon',attrs,false),on),[_vm._v(" mdi-filter ")])]}}])},[_c('span',[_vm._v("Filtrar locações")])])],1)]),_c('v-expansion-panel-content',[_c('v-container',{attrs:{"fluid":""}},[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('ValidationProvider',{attrs:{"name":"número da locação","rules":"integer"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-combobox',{attrs:{"multiple":"","disable-lookup":"","label":"Número da locação","append-icon":"","small-chips":"","hint":"Números separados por vírgula permitem filtrar mais de uma locação","clearable":"","delimiters":[','],"error-messages":errors,"deletable-chips":""},on:{"change":function($event){return handleSubmit(_vm.filter)}},model:{value:(_vm.publicId),callback:function ($$v) {_vm.publicId=$$v},expression:"publicId"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-select',{attrs:{"multiple":"","items":_vm.rentStatuses,"item-value":"id","item-text":"name","chips":"","small-chips":"","clearable":"","label":"Situação da locação"},on:{"change":function($event){return handleSubmit(_vm.filter)}},model:{value:(_vm.rentStatusId),callback:function ($$v) {_vm.rentStatusId=$$v},expression:"rentStatusId"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-select',{attrs:{"items":_vm.paymentStatuses,"item-value":"id","item-text":"name","small-chips":"","clearable":"","label":"Situação do pagamento"},on:{"change":function($event){return handleSubmit(_vm.filter)}},model:{value:(_vm.paymentStatusId),callback:function ($$v) {_vm.paymentStatusId=$$v},expression:"paymentStatusId"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('ValidationProvider',{attrs:{"name":"número da caçamba","rules":"integer"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-combobox',{attrs:{"multiple":"","disable-lookup":"","label":"Número da caçamba","append-icon":"","small-chips":"","hint":"Números separados por vírgula permitem filtrar mais de uma caçamba","clearable":"","delimiters":[','],"error-messages":errors,"deletable-chips":""},on:{"change":function($event){return handleSubmit(_vm.filter)}},model:{value:(_vm.dumpsterNumber),callback:function ($$v) {_vm.dumpsterNumber=$$v},expression:"dumpsterNumber"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-autocomplete',{attrs:{"items":_vm.searchByClientNameItems,"loading":_vm.isLoadingByClientName,"search-input":_vm.searchRentsByClientName,"small-chips":"","clearable":"","hide-details":"","return-object":"","itemid":"client.id","item-text":"client.name","item-value":"client.id","label":"Nome do cliente","no-data-text":"Nenhum dado encontrado"},on:{"change":_vm.filter,"update:searchInput":function($event){_vm.searchRentsByClientName=$event},"update:search-input":function($event){_vm.searchRentsByClientName=$event}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.client.name)}}),_c('v-list-item-subtitle',{domProps:{"textContent":_vm._s(_vm.parseAddress(item))}})],1)]}}],null,true),model:{value:(_vm.clientByClientName),callback:function ($$v) {_vm.clientByClientName=$$v},expression:"clientByClientName"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-autocomplete',{attrs:{"items":_vm.searchByStreetNameItems,"loading":_vm.isLoadingByStreetName,"search-input":_vm.searchRentsByStreetName,"small-chips":"","clearable":"","hide-details":"","return-object":"","itemid":"street_name","item-text":"street_name","item-value":"street_name","label":"Endereço da locação","no-data-text":"Nenhum dado encontrado"},on:{"change":_vm.filter,"update:searchInput":function($event){_vm.searchRentsByStreetName=$event},"update:search-input":function($event){_vm.searchRentsByStreetName=$event}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.street_name)}})],1)]}}],null,true),model:{value:(_vm.clientByStreetName),callback:function ($$v) {_vm.clientByStreetName=$$v},expression:"clientByStreetName"}})],1)],1)]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }