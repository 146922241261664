<template>
  <div>
    <recover-password
      v-if="dialogs.recoverPassword"
      @close="dialogs.recoverPassword = false"
    />
    <create-account
      v-if="dialogs.createAccount"
      @close="dialogs.createAccount = false"
    />

    <ValidationObserver v-slot="{ invalid, validated, handleSubmit }">
      <v-main>
        <v-container fluid>
          <v-layout align-center justify-center>
            <v-flex xs12 sm8 md4>
              <v-card>
                <v-toolbar dark color="primary">
                  <v-toolbar-title> Login </v-toolbar-title>
                </v-toolbar>
                <v-card-text>
                  <v-form>
                    <ValidationProvider
                      name="email"
                      rules="required|email"
                      v-slot="{ errors, valid }"
                    >
                      <v-text-field
                        prepend-icon="mdi-email"
                        name="email"
                        label="E-mail"
                        type="email"
                        v-model="email"
                        :error-messages="errors"
                        :success="valid"
                        @keyup.enter="handleSubmit(submit)"
                      ></v-text-field>
                    </ValidationProvider>
                    <ValidationProvider
                      name="senha"
                      rules="required"
                      v-slot="{ errors, valid }"
                    >
                      <v-text-field
                        id="password"
                        prepend-icon="mdi-lock"
                        name="password"
                        label="Senha"
                        type="password"
                        v-model="password"
                        :error-messages="errors"
                        :success="valid"
                        @keyup.enter="handleSubmit(submit)"
                      ></v-text-field>
                    </ValidationProvider>
                  </v-form>
                </v-card-text>
                <v-card-actions>
                  <v-btn
                    color="primary"
                    block
                    @click="handleSubmit(submit)"
                    :disabled="invalid || !validated"
                    >Entrar</v-btn
                  >
                </v-card-actions>
                <v-row align="center" justify="space-around" class="py-5">
                  <v-btn
                    text
                    color="primary"
                    @click="dialogs.createAccount = true"
                  >
                    Criar conta
                  </v-btn>
                  <v-btn text @click="dialogs.recoverPassword = true">
                    Recuperar senha
                  </v-btn>
                </v-row>
              </v-card>
            </v-flex>
          </v-layout>
        </v-container>
      </v-main>
    </ValidationObserver>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import auth from "@/mixins/auth";
import RecoverPassword from "./dialogs/RecoverPassword";
import CreateAccount from "./dialogs/CreateAccount";

export default {
  data() {
    return {
      password: "",
      email: "",
      dialogs: {
        recoverPassword: false,
        createAccount: false,
      },
    };
  },
  components: {
    ValidationProvider,
    ValidationObserver,
    RecoverPassword,
    CreateAccount,
  },
  mixins: [auth],
  created() {
    if (this.getTokenWithExpiry(auth.TOKEN)) {
      this.$router.push({ name: "Rents" }, () => {});
    }
  },
  methods: {
    async submit() {
      this.$store
        .dispatch("login", {
          email: this.email,
          password: this.password,
        })
        .then(() => this.$router.push({ name: "Rents" }));
    },
  },
};
</script>

<style></style>
