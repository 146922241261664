<template>
  <v-dialog value="true" persistent max-width="500px">
    <v-card>
      <v-progress-linear
        indeterminate
        color="primary"
        absolute
        :active="loading"
      ></v-progress-linear>
      <v-card-title>
        <span class="text-h5"
          >Agendar Retirada Locação Nº {{ item.public_id }}</span
        >
      </v-card-title>
      <v-card-text> Deseja agendar a retidada? </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="$emit('close')">
          Fechar
        </v-btn>
        <v-btn color="blue darken-1" text @click="scheduleWithdraw">
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ["item"],
  data() {
    return {
      loading: false,
    };
  },

  methods: {
    scheduleWithdraw() {
      this.loading = true;

      this.$store
        .dispatch("scheduleWithdrawRent", {
          rentId: this.item.id,
        })
        .then(() => {
          this.loading = false;
          this.$emit("close");
        })
        .catch(() => {
          this.loading = false;
          this.$emit("close");
        });
    },
  },
};
</script>

<style>
</style>
