<template>
  <div>
    <create-rent
      v-if="dialogs.createRent"
      @close="dialogs.createRent = false"
    />
    <create-client
      v-if="dialogs.createClient"
      @close="dialogs.createClient = false"
    />
    <create-dumpster
      v-if="dialogs.createDumpster"
      @close="dialogs.createDumpster = false"
    />
    <v-data-table
      :headers="headers"
      :header-props="headerProps"
      :items="rents"
      :page.sync="page"
      :items-per-page="itemsPerPage"
      hide-default-footer
      show-expand
      single-expand
      item-key="id"
      no-data-text="Nenhum dado encontrado"
    >
      <template v-slot:top>
        <rent-filter />
        <v-toolbar flat>
          <v-speed-dial bottom left absolute direction="right">
            <template #activator>
              <v-btn color="pink" fab x-small dark>
                <v-icon dark> mdi-plus </v-icon>
              </v-btn>
            </template>
            <v-btn x-small color="primary" @click="dialogs.createRent = true">
              Locação</v-btn
            >
            <v-btn x-small color="primary" @click="dialogs.createClient = true"> Cliente</v-btn>
            <v-btn
              x-small
              color="primary"
              @click="dialogs.createDumpster = true"
            >
              Caçamba</v-btn
            >
          </v-speed-dial>
        </v-toolbar>
      </template>

      <template v-slot:expanded-item="{ headers, item }">
        <rent-expanded-info :headers="headers" :item="item" />
      </template>
      <template v-slot:item.id="{ item }">
        <rent-id :item="item" />
      </template>
      <template v-slot:item.client="{ item }">
        <rent-client :item="item" />
      </template>
      <template v-slot:item.address="{ item }">
        <rent-address :item="item" />
      </template>
      <template v-slot:item.price="{ item }">
        <rent-price :item="item" />
      </template>
      <template v-slot:item.situation="{ item }">
        <rent-status :item="item" />
      </template>
      <template v-slot:item.actions="{ item }">
        <rent-menu :item="item" />
      </template>
    </v-data-table>
    <div class="text-center">
      <v-divider></v-divider>
      <v-container>
        <v-row justify="center">
          <v-col>
            <v-container class="max-width">
              <v-pagination
                v-model="page"
                :length="pageCount"
                :total-visible="7"
              ></v-pagination>
            </v-container>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import RentExpandedInfo from "./RentExpandedInfo";
import RentPrice from "./RentPrice";
import RentStatus from "./RentStatus";
import RentMenu from "./RentMenu";
import RentId from "./RentId";
import RentClient from "./RentClient";
import RentAddress from "./RentAddress";
import RentFilter from "./RentFilter";
import filters from "@/mixins/rent/filters";
import CreateRent from "./dialogs/CreateRent";
import CreateDumpster from "../dumpster/dialogs/CreateDumpster";
import CreateClient from "../client/dialogs/CreateClient";

export default {
  mixins: [filters],
  data() {
    return {
      dialogs: {
        createRent: false,
        createDumpster: false,
        createClient: false,
      },
      page: parseInt(this.$route.query.page) || 1,
      headerProps: {
        sortByText: "Ordenar por",
      },
      headers: [
        { text: "Nº", align: "start", value: "id" },
        { text: "Cliente", value: "client", sortable: false },
        { text: "Endereço", value: "address", sortable: false },
        { text: "Valor", value: "price" },
        { text: "Situação", value: "situation", sortable: false },
        { value: "actions", sortable: false },
      ],
    };
  },
  components: {
    RentExpandedInfo,
    RentPrice,
    RentStatus,
    RentMenu,
    RentId,
    RentClient,
    RentAddress,
    RentFilter,
    CreateRent,
    CreateDumpster,
    CreateClient,
  },
  watch: {
    "$route.path": {
      immediate: true,
      handler(to, from) {
        this.$store.commit("resetRents");
      },
    },
    "$route.query.page": function (value) {
      const number = parseInt(value);
      if (!isNaN(number)) {
        this.page = number;
      }
    },
    page(value) {
      const query = { ...this.$route.query, page: value };
      this.$router.replace({ query }, () => {});
      this.getRents(value);
    },
  },
  computed: {
    ...mapGetters({
      totalPages: "paginationTotalPagesRents",
      rents: "allRents",
      itemsPerPage: "paginationPerPageRents",
      pageCount: "paginationTotalPagesRents",
    }),
  },
  methods: {
    getRents(page) {
      this.$store.dispatch("getRents", {
        pageNumber: page,
        publicId: this.parseAttributeFromRoute(this.$route, "publicId"),
        rentStatusId: this.parseListToInt(
          this.parseAttributeFromRoute(this.$route, "rentStatusId")
        ),
        dumpsterNumber: this.parseAttributeFromRoute(
          this.$route,
          "dumpsterNumber"
        ),
        paymentStatusId: parseInt(this.$route.query.paymentStatusId) || null,
        clientId: this.$route.query.clientId || null,
        clientName: this.$route.query.clientName || null,
        streetName: this.$route.query.streetName || null,
      });
    },
  },
  mounted() {
    this.getRents(this.page);
  },
};
</script>

<style>
</style>
