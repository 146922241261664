<template>
  <ValidationObserver v-slot="{ invalid, validated, handleSubmit }">
    <v-main>
      <v-container fluid>
        <v-layout align-center justify-center>
          <v-flex xs12 sm8 md4>
            <v-card>
              <v-toolbar dark color="primary">
                <v-toolbar-title> Redefinir senha </v-toolbar-title>
              </v-toolbar>
              <v-card-text>
                <v-form>
                  <ValidationProvider
                    name="senha"
                    rules="required"
                    v-slot="{ errors, valid }"
                  >
                    <v-text-field
                      id="password"
                      prepend-icon="mdi-lock"
                      name="password"
                      label="Senha"
                      type="password"
                      v-model="password"
                      :error-messages="errors"
                      :success="valid"
                      @keyup.enter="handleSubmit(submit)"
                    ></v-text-field>
                  </ValidationProvider>
                  <ValidationProvider
                    name="repetir senha"
                    rules="required|confirmed:senha"
                    v-slot="{ errors, valid }"
                  >
                    <v-text-field
                      id="password_confirmation"
                      prepend-icon="mdi-lock"
                      name="password_confirmation"
                      label="Repertir senha"
                      type="password"
                      v-model="passwordConfirmation"
                      :error-messages="errors"
                      :success="valid"
                      @keyup.enter="handleSubmit(submit)"
                    ></v-text-field>
                  </ValidationProvider>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-btn
                  color="primary"
                  block
                  @click="handleSubmit(submit)"
                  :disabled="invalid || !validated"
                  >Redefinir</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </v-main>
  </ValidationObserver>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";

export default {
  data() {
    return {
      password: "",
      passwordConfirmation: "",
      token: this.$route.query.token,
      email: this.$route.query.email,
    };
  },
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  created() {},
  methods: {
    async submit() {
      this.$store
        .dispatch("resetPassword", {
          password: this.password,
          passwordConfirmation: this.passwordConfirmation,
          token: this.token,
          email: this.email,
        })
        .then(() => this.$router.push({ name: "Rents" }));
    },
  },
};
</script>

<style></style>
