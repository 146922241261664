<template>
  <v-dialog value="true" persistent max-width="500px">
    <ValidationObserver v-slot="{ invalid, validated, handleSubmit }">
      <v-card>
        <v-progress-linear
          indeterminate
          color="primary"
          absolute
          :active="loading"
        ></v-progress-linear>
        <v-card-title>
          <span class="text-h5">Recuperar Senha</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <ValidationProvider
                  name="email"
                  rules="required|email"
                  v-slot="{ errors, valid }"
                >
                  <v-text-field
                    prepend-icon="mdi-email"
                    name="email"
                    label="E-mail"
                    type="email"
                    v-model="email"
                    :error-messages="errors"
                    :success="valid"
                    @keyup.enter="handleSubmit(submit)"
                  ></v-text-field>
                </ValidationProvider>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="$emit('close')">
            Fechar
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="handleSubmit(submit)"
            :disabled="invalid || !validated"
          >
            Enviar
          </v-btn>
        </v-card-actions>
      </v-card>
    </ValidationObserver>
  </v-dialog>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
export default {
  props: ["item"],
  data() {
    return {
      email: "",
      loading: false,
    };
  },
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  methods: {
    async submit() {
      this.loading = true;

      this.$store
        .dispatch("recoverPassword", {
          email: this.email,
        })
        .then(() => {
          this.loading = false;
          this.$emit("close");
        })
        .catch(() => {
          this.loading = false;
          this.$emit("close");
        });
    },
  },
};
</script>

<style>
</style>
