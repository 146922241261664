<template>
  <div>
    <v-expansion-panels multiple>
      <v-expansion-panel>
        <v-expansion-panel-header>Locações</v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-container fluid>
            <v-row>
              <v-col cols="12" sm="6" md="4" lg="2">
                <v-card class="mx-auto" color="grey lighten-4">
                  <v-card-title>
                    <v-row align="center" class="justify-center">
                      <div class="caption grey--text text-uppercase pr-2">
                        A Colocar
                      </div>
                      <div>
                        <span
                          class="display-1 font-weight-regular"
                          v-text="allMetrics.rents.to_place || '—'"
                        ></span>
                      </div>
                    </v-row>
                  </v-card-title>
                </v-card>
              </v-col>
              <v-col cols="12" sm="6" md="4" lg="2">
                <v-card class="mx-auto" color="grey lighten-4">
                  <v-card-title>
                    <v-row align="center" class="justify-center">
                      <div class="caption grey--text text-uppercase pr-2">
                        A Retirar
                      </div>
                      <div>
                        <span
                          class="display-1 font-weight-regular"
                          v-text="allMetrics.rents.to_withdraw || '—'"
                        ></span>
                      </div>
                    </v-row>
                  </v-card-title>
                </v-card>
              </v-col>
              <v-col cols="12" sm="6" md="4" lg="2">
                <v-card class="mx-auto" color="grey lighten-4">
                  <v-card-title>
                    <v-row align="center" class="justify-center">
                      <div class="caption grey--text text-uppercase pr-2">
                        Locações hoje
                      </div>
                      <div>
                        <span
                          class="display-1 font-weight-regular"
                          v-text="allMetrics.rents.today || '—'"
                        ></span>
                      </div>
                    </v-row>
                  </v-card-title>
                </v-card>
              </v-col>
              <v-col cols="12" sm="6" md="4" lg="2">
                <v-card class="mx-auto" color="grey lighten-4">
                  <v-card-title>
                    <v-row align="center" class="justify-center">
                      <div class="caption grey--text text-uppercase pr-2">
                        Locações ontem
                      </div>
                      <div>
                        <span
                          class="display-1 font-weight-regular"
                          v-text="allMetrics.rents.yesterday || '—'"
                        ></span>
                      </div>
                    </v-row>
                  </v-card-title>
                </v-card>
              </v-col>
              <v-col cols="12" sm="6" md="4" lg="2">
                <v-card class="mx-auto" color="grey lighten-4">
                  <v-card-title>
                    <v-row align="center" class="justify-center">
                      <div class="caption grey--text text-uppercase pr-2">
                        Locações este mês
                      </div>
                      <div>
                        <span
                          class="display-1 font-weight-regular"
                          v-text="allMetrics.rents.month || '—'"
                        ></span>
                      </div>
                    </v-row>
                  </v-card-title>
                </v-card>
              </v-col>
              <v-col cols="12" sm="6" md="4" lg="2">
                <v-card class="mx-auto" color="grey lighten-4">
                  <v-card-title>
                    <v-row align="center" class="justify-center">
                      <div class="caption grey--text text-uppercase pr-2">
                        Locações mês passado
                      </div>
                      <div>
                        <span
                          class="display-1 font-weight-regular"
                          v-text="allMetrics.rents.last_month || '—'"
                        ></span>
                      </div>
                    </v-row>
                  </v-card-title>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header>Clientes</v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-container fluid>
            <v-row>
              <v-col cols="12" sm="6" md="4" lg="2">
                <v-card class="mx-auto" color="grey lighten-4">
                  <v-card-title>
                    <v-row align="center" class="justify-center">
                      <div class="caption grey--text text-uppercase pr-2">
                        Cadastrados hoje
                      </div>
                      <div>
                        <span
                          class="display-1 font-weight-regular"
                          v-text="allMetrics.clients.today || '—'"
                        ></span>
                      </div>
                    </v-row>
                  </v-card-title>
                </v-card>
              </v-col>
              <v-col cols="12" sm="6" md="4" lg="2">
                <v-card class="mx-auto" color="grey lighten-4">
                  <v-card-title>
                    <v-row align="center" class="justify-center">
                      <div class="caption grey--text text-uppercase pr-2">
                        Cadastrados ontem
                      </div>
                      <div>
                        <span
                          class="display-1 font-weight-regular"
                          v-text="allMetrics.clients.yesterday || '—'"
                        ></span>
                      </div>
                    </v-row>
                  </v-card-title>
                </v-card>
              </v-col>
              <v-col cols="12" sm="6" md="4" lg="2">
                <v-card class="mx-auto" color="grey lighten-4">
                  <v-card-title>
                    <v-row align="center" class="justify-center">
                      <div class="caption grey--text text-uppercase pr-2">
                        Cadastrados este mês
                      </div>
                      <div>
                        <span
                          class="display-1 font-weight-regular"
                          v-text="allMetrics.clients.month || '—'"
                        ></span>
                      </div>
                    </v-row>
                  </v-card-title>
                </v-card>
              </v-col>
              <v-col cols="12" sm="6" md="4" lg="2">
                <v-card class="mx-auto" color="grey lighten-4">
                  <v-card-title>
                    <v-row align="center" class="justify-center">
                      <div class="caption grey--text text-uppercase pr-2">
                        Cadastrados mês passado
                      </div>
                      <div>
                        <span
                          class="display-1 font-weight-regular"
                          v-text="allMetrics.rents.last_month || '—'"
                        ></span>
                      </div>
                    </v-row>
                  </v-card-title>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header>Caçambas</v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-container fluid>
            <v-row>
              <v-col cols="12" sm="6" md="4" lg="2">
                <v-card class="mx-auto" color="grey lighten-4">
                  <v-card-title>
                    <v-row align="center" class="justify-center">
                      <div class="caption grey--text text-uppercase pr-2">
                        Disponíveis
                      </div>
                      <div>
                        <span
                          class="display-1 font-weight-regular"
                          v-text="allMetrics.dumpsters.available || '—'"
                        ></span>
                      </div>
                    </v-row>
                  </v-card-title>
                </v-card>
              </v-col>
              <v-col cols="12" sm="6" md="4" lg="2">
                <v-card class="mx-auto" color="grey lighten-4">
                  <v-card-title>
                    <v-row align="center" class="justify-center">
                      <div class="caption grey--text text-uppercase pr-2">
                        Alugadas
                      </div>
                      <div>
                        <span
                          class="display-1 font-weight-regular"
                          v-text="allMetrics.dumpsters.rented || '—'"
                        ></span>
                      </div>
                    </v-row>
                  </v-card-title>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header>Pagamentos</v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-container fluid>
            <v-row>
              <v-col cols="12" sm="6" md="4" lg="2">
                <v-card class="mx-auto" color="grey lighten-4">
                  <v-card-title>
                    <v-row align="center" class="justify-center">
                      <div class="caption grey--text text-uppercase pr-2">
                        A Receber
                      </div>
                      <div>
                        <span
                          class="display-1 font-weight-regular"
                          v-text="
                            parsePrice(allMetrics.payments.waiting_payment) ||
                            '—'
                          "
                        ></span>
                      </div>
                    </v-row>
                  </v-card-title>
                </v-card>
              </v-col>
              <v-col cols="12" sm="6" md="4" lg="2">
                <v-card class="mx-auto" color="grey lighten-4">
                  <v-card-title>
                    <v-row align="center" class="justify-center">
                      <div class="caption grey--text text-uppercase pr-2">
                        Recebido hoje
                      </div>
                      <div>
                        <span
                          class="display-1 font-weight-regular"
                          v-text="parsePrice(allMetrics.payments.today) || '—'"
                        ></span>
                      </div>
                    </v-row>
                  </v-card-title>
                </v-card>
              </v-col>
              <v-col cols="12" sm="6" md="4" lg="2">
                <v-card class="mx-auto" color="grey lighten-4">
                  <v-card-title>
                    <v-row align="center" class="justify-center">
                      <div class="caption grey--text text-uppercase pr-2">
                        Recebido ontem
                      </div>
                      <div>
                        <span
                          class="display-1 font-weight-regular"
                          v-text="
                            parsePrice(allMetrics.payments.yesterday) || '—'
                          "
                        ></span>
                      </div>
                    </v-row>
                  </v-card-title>
                </v-card>
              </v-col>
              <v-col cols="12" sm="6" md="4" lg="2">
                <v-card class="mx-auto" color="grey lighten-4">
                  <v-card-title>
                    <v-row align="center" class="justify-center">
                      <div class="caption grey--text text-uppercase pr-2">
                        Recebido este mês
                      </div>
                      <div>
                        <span
                          class="display-1 font-weight-regular"
                          v-text="parsePrice(allMetrics.payments.month) || '—'"
                        ></span>
                      </div>
                    </v-row>
                  </v-card-title>
                </v-card>
              </v-col>
              <v-col cols="12" sm="6" md="4" lg="2">
                <v-card class="mx-auto" color="grey lighten-4">
                  <v-card-title>
                    <v-row align="center" class="justify-center">
                      <div class="caption grey--text text-uppercase pr-2">
                        Recebido mês passado
                      </div>
                      <div>
                        <span
                          class="display-1 font-weight-regular"
                          v-text="
                            parsePrice(allMetrics.payments.last_month) || '—'
                          "
                        ></span>
                      </div>
                    </v-row>
                  </v-card-title>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  mounted() {
    this.$store.dispatch("getAllMetrics");
  },
  methods: {
    parsePrice(value) {
      if (value > 0) {
        return parseFloat(value).toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        });
      }
    },
  },
  computed: {
    ...mapGetters({
      allMetrics: "allMetrics",
    }),
  },
};
</script>

<style>
</style>
