<template>
  <span
    class="text-wrap"
    >{{ address }}</span
  >
</template>

<script>
import address from "@/mixins/rent/address";

export default {
  props: ["item"],
  mixins: [address],
  computed: {
    address() {
      return this.parseAddress(this.item?.addresses?.data[0]);
    },
  },
};
</script>

<style>
</style>
