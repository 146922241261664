<template>
  <div>
    <v-expansion-panels flat v-model="panel" tile>
      <v-expansion-panel>
        <v-expansion-panel-header color="grey lighten-3">
          <div>
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-icon dark color="grey darken-1" v-bind="attrs" v-on="on">
                  mdi-filter
                </v-icon>
              </template>
              <span>Filtrar caçambas</span>
            </v-tooltip>
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-container fluid>
            <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
              <v-row>
                <v-col cols="12" md="6">
                  <ValidationProvider
                    name="número da caçamba"
                    rules="integer"
                    v-slot="{ errors }"
                  >
                    <v-combobox
                      multiple
                      v-model="dumpsterNumber"
                      disable-lookup
                      @change="handleSubmit(filter)"
                      label="Número da caçamba"
                      append-icon
                      small-chips
                      hint="Números separados por vírgula permitem filtrar mais de uma caçamba"
                      clearable
                      :delimiters="[',']"
                      :error-messages="errors"
                      deletable-chips
                    >
                    </v-combobox>
                  </ValidationProvider>
                </v-col>
              </v-row>
            </ValidationObserver>
          </v-container>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import filters from "@/mixins/rent/filters";

export default {
  mixins: [filters],
  data() {
    return {
      dumpsterNumber: this.getDumpsterNumber(),
      singleExpand: true,
      panel: 0,
    };
  },
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  watch: {

  },
  methods: {
       getDumpsterNumber() {
      return this.parseAttributeFromRoute(this.$route, "dumpsterNumber");
    },
    async filter() {
      const query = {
        ...this.$route.query,
        page: 1,
        dumpsterNumber: [this.dumpsterNumber].join() || undefined,
      };

      this.$router.push({ query }, () => {});

      this.$store.dispatch("getDumpsters", {
        pageNumber: 1,
        dumpsterNumber: this.dumpsterNumber,
      });
    },
  },
};
</script>

<style>
</style>
