import client from '../../mixins/api/client'

function initialState() {
    return {
        search: {
            allMetrics: {
                rents: {},
                clients: {},
                dumpsters: {},
                payments: {
                    last_month: 0,
                    month: 0,
                    today: 0,
                    waiting_payment: 0,
                    yesterday: 0
                },
            },
        },
    }
}

export default {
    state: initialState,
    mutations: {
        setAllMetrics(state, { response: { data } }) {
            state.search.allMetrics = data;
        },
    },
    actions: {
        getAllMetrics({ commit, getters }) {
            return new Promise((resolve, reject) => {
                client
                    .methods
                    .get(`/v1/companies/${getters.userCompanyId}/metrics`)
                    .then(response => {
                        commit('setAllMetrics', { response })
                        resolve()
                    })
                    .catch(err => {
                        commit("showErrorSnackbar", err)
                        reject(err)
                    });
            })
        },
    },
    getters: {
        allMetrics: (state) => state.search.allMetrics,
    }
}
