<template>
  <v-dialog value="true" persistent max-width="500px">
    <ValidationObserver v-slot="{ invalid, validated, handleSubmit }">
      <v-card>
        <v-progress-linear
          indeterminate
          color="primary"
          absolute
          :active="loading"
        ></v-progress-linear>
        <v-card-title>
          <span class="text-h5">Criar Conta</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <ValidationProvider
                  name="nome"
                  rules="required|min:3"
                  v-slot="{ errors, valid }"
                >
                  <v-text-field
                    prepend-icon="mdi-account"
                    name="name"
                    label="Nome"
                    v-model="name"
                    :error-messages="errors"
                    :success="valid"
                    @keyup.enter="handleSubmit(submit)"
                  ></v-text-field>
                </ValidationProvider>
                <ValidationProvider
                  name="email"
                  rules="required|email"
                  v-slot="{ errors, valid }"
                >
                  <v-text-field
                    prepend-icon="mdi-email"
                    name="email"
                    label="E-mail"
                    type="email"
                    v-model="email"
                    :error-messages="errors"
                    :success="valid"
                    @keyup.enter="handleSubmit(submit)"
                  ></v-text-field>
                </ValidationProvider>
                <ValidationProvider
                  name="senha"
                  rules="required|min:6"
                  v-slot="{ errors, valid }"
                >
                  <v-text-field
                    id="password"
                    prepend-icon="mdi-lock"
                    name="password"
                    label="Senha"
                    type="password"
                    v-model="password"
                    :error-messages="errors"
                    :success="valid"
                    @keyup.enter="handleSubmit(submit)"
                  ></v-text-field>
                </ValidationProvider>
                <ValidationProvider
                  name="repetir senha"
                  rules="required|confirmed:senha"
                  v-slot="{ errors, valid }"
                >
                  <v-text-field
                    id="password_confirmation"
                    prepend-icon="mdi-lock"
                    name="password_confirmation"
                    label="Repertir senha"
                    type="password"
                    v-model="passwordConfirmation"
                    :error-messages="errors"
                    :success="valid"
                    @keyup.enter="handleSubmit(submit)"
                  ></v-text-field>
                </ValidationProvider>
                <ValidationProvider
                  name="empresa"
                  rules="required|min:3"
                  v-slot="{ errors, valid }"
                >
                  <v-text-field
                    prepend-icon="mdi-domain"
                    name="company"
                    label="Empresa"
                    v-model="company"
                    :error-messages="errors"
                    :success="valid"
                    @keyup.enter="handleSubmit(submit)"
                  ></v-text-field>
                </ValidationProvider>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="$emit('close')">
            Fechar
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="handleSubmit(submit)"
            :disabled="invalid || !validated"
          >
            Enviar
          </v-btn>
        </v-card-actions>
      </v-card>
    </ValidationObserver>
  </v-dialog>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
export default {
  props: ["item"],
  data() {
    return {
      email: "",
      password: "",
      name: "",
      passwordConfirmation: "",
      loading: false,
      company: "",
    };
  },
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  methods: {
    async submit() {
      this.loading = true;

      this.$store
        .dispatch("createAccount", {
          email: this.email,
          name: this.name,
          password: this.password,
          company: this.company,
        })
        .then(() => {
          this.loading = false;
          this.$emit("close");
          this.$router.push({ name: "Rents" });
        })
        .catch(() => {
          this.loading = false;
          this.$emit("close");
        });
    },
  },
};
</script>

<style>
</style>
