import client from '../../mixins/api/client'
import auth from '../../mixins/auth'

export default {
    state: {
        accessToken: auth.methods.getTokenWithExpiry(auth.TOKEN),
        companyId: null,
    },
    mutations: {
        setLoggedUser(state, { access_token, company_id, expires_in }) {
            auth.methods.setTokenWithExpiry(auth.TOKEN, access_token, expires_in * 1000);
            localStorage.setItem(auth.COMPANY_ID, company_id)

            state.accessToken = access_token;
            state.companyId = company_id;
        },
        logoutUser(state) {
            localStorage.clear();

            state.accessToken = null;
            state.companyId = null;
        },
        setAccessToken(state, accessToken) {
            state.accessToken = accessToken;
        },
        setCompanyId(state, companyId) {
            state.companyId = companyId;
        }
    },
    actions: {
        login({ commit }, { email, password }) {
            commit("showLoading");
            return new Promise((resolve, reject) => {
                client
                    .methods
                    .post("/v1/auth/login", {
                        email: email,
                        password: password,
                    })
                    .then(response => {
                        commit("hideLoading");
                        commit('setLoggedUser', response.data)
                        resolve()
                    })
                    .catch(err => {
                        commit("hideLoading");
                        commit(
                            "showErrorSnackbar",
                            err.response.data.errors || err.response.data.message,
                        )
                        reject(err)
                    });
            })
        },
        logout({ commit }) {
            commit("showLoading");
            return new Promise((resolve, reject) => {
                client.methods.post('/v1/auth/logout', {}).then(() => {
                    commit("hideLoading");
                    commit('logoutUser')
                    resolve()
                }).catch(err => {
                    commit("hideLoading");
                    reject(err)
                });
            })
        }
    },
    getters: {
        userCompanyId: (state) => state.companyId,
        userAccessToken: (state) => state.accessToken,
        userLogged: (state) => !!state.accessToken,
    }
}
