import client from '../../mixins/api/client'
import moment from 'moment-timezone'

function initialState() {
    return {
        rents: [],
        pagination: {
            currentPage: 1,
            totalPages: 0,
            perPage: 10,
        },
        filter: {
            publicId: [],
            dumpsterNumber: [],
            rentStatusId: [],
            paymentStatusId: null,
        },
        search: {
            rentsByClientName: [],
            rentsByStreetName: [],
        },
        include: 'client,dumpster,payment_status,rent_state,rent_status'
    }
}

function query(state) {
    return {
        pageNumber: state.pagination.currentPage,
        publicId: state.filter.publicId,
        dumpsterNumber: state.filter.dumpsterNumber,
        paymentStatusId: state.filter.paymentStatusId,
        rentStatusId: state.filter.rentStatusId,
        clientId: state.filter.clientId,
    }
}

export default {
    state: initialState,
    mutations: {
        setRents(state, { query: query, response: { data: { data, meta: { pagination } } } }) {
            state.rents = data;
            state.pagination.currentPage = pagination.current_page;
            state.pagination.totalPages = pagination.total_pages;
            state.pagination.perPage = pagination.per_page;
            state.filter.publicId = query.publicId;
            state.filter.dumpsterNumber = query.dumpsterNumber;
            state.filter.paymentStatusId = query.paymentStatusId;
            state.filter.rentStatusId = query.rentStatusId;
            state.filter.clientId = query.clientId;
        },
        setSearchByClientName(state, { response: { data: { data } } }) {
            state.search.rentsByClientName = data;
        },
        setSearchByStreetName(state, { response: { data: { data } } }) {
            state.search.rentsByStreetName = data;
        },
        resetRents(state) {
            const s = initialState()
            Object.keys(s).forEach(key => {
                state[key] = s[key]
            })
        }
    },
    actions: {
        searchRentsByStreetName({ commit, getters, state }, query) {
            const { streetName = null } = query;

            return new Promise((resolve, reject) => {
                client
                    .methods
                    .get(`/v1/companies/${getters.userCompanyId}/rents`, {
                        params: {
                            include: state.include,
                            'filter[street_name]': streetName,
                        }
                    })
                    .then(response => {
                        commit('setSearchByStreetName', { response })
                        resolve()
                    })
                    .catch(err => {
                        commit("showErrorSnackbar", err)
                        reject(err)
                    });
            })
        },
        searchRentsByClientName({ commit, getters, state }, query) {
            const { clientName = null } = query;

            return new Promise((resolve, reject) => {
                client
                    .methods
                    .get(`/v1/companies/${getters.userCompanyId}/rents`, {
                        params: {
                            include: state.include,
                            'filter[client.name]': clientName,
                        }
                    })
                    .then(response => {
                        commit('setSearchByClientName', { response })
                        resolve()
                    })
                    .catch(err => {
                        commit("showErrorSnackbar", err)
                        reject(err)
                    });
            })
        },
        getRents({ commit, getters, state }, query) {
            commit("showLoading");

            const {
                pageNumber = 1,
                clientId = null,
                streetName = null,
                publicId = [],
                dumpsterNumber = [],
                rentStatusId = [],
                paymentStatusId = null
            } = query;

            return new Promise((resolve, reject) => {
                client
                    .methods
                    .get(`/v1/companies/${getters.userCompanyId}/rents`, {
                        params: {
                            include: state.include,
                            'page[size]': state.pagination.perPage,
                            'page[number]': pageNumber,
                            'filter[public_id]': [publicId].flat().join(),
                            'filter[dumpster.number]': [dumpsterNumber].flat().join(),
                            'filter[payment_status]': paymentStatusId,
                            'filter[rent_status_id]': [rentStatusId].flat().join(),
                            'filter[client_id]': clientId,
                            'filter[street_name]': streetName,
                        }
                    })
                    .then(response => {
                        commit("hideLoading");
                        commit('setRents', { response, query })
                        resolve()
                    })
                    .catch(err => {
                        commit("hideLoading");
                        commit("showErrorSnackbar", err)
                        reject(err)
                    });
            })
        },
        cancelRent({ dispatch, getters, state, commit }, { rentId, cancelReason }) {
            return new Promise((resolve, reject) => {
                client
                    .methods
                    .patch(`/v1/companies/${getters.userCompanyId}/rents/${rentId}/cancel`,
                        {
                            cancel_reason: cancelReason,
                            cancel_date: moment().tz("America/Sao_Paulo").format('YYYY-MM-DD'),
                        })
                    .then(() => {
                        commit("showSuccessSnackbar", "Operação realizada com sucesso!")
                        dispatch("getRents", query(state));
                        resolve()
                    })
                    .catch(err => {
                        commit("showErrorSnackbar", err)
                        reject(err)
                    });
            })
        },
        payRent({ dispatch, getters, state, commit }, { rentId }) {
            return new Promise((resolve, reject) => {
                client
                    .methods
                    .patch(`/v1/companies/${getters.userCompanyId}/rents/${rentId}/pay`,
                        {
                            payment_date: moment().tz("America/Sao_Paulo").format('YYYY-MM-DD'),
                        })
                    .then(() => {
                        commit("showSuccessSnackbar", "Operação realizada com sucesso!")
                        dispatch("getRents", query(state));
                        resolve()
                    })
                    .catch(err => {
                        commit("showErrorSnackbar", err)
                        reject(err)
                    });
            })
        },
        withdrawRent({ dispatch, getters, state, commit }, { rentId, devolutionDate }) {
            return new Promise((resolve, reject) => {
                client
                    .methods
                    .patch(`/v1/companies/${getters.userCompanyId}/rents/${rentId}/withdraw`,
                        {
                            devolution_date: devolutionDate,
                        })
                    .then(() => {
                        commit("showSuccessSnackbar", "Operação realizada com sucesso!")
                        dispatch("getRents", query(state));
                        resolve()
                    })
                    .catch(err => {
                        commit("showErrorSnackbar", err)
                        reject(err)
                    });
            })
        },
        scheduleWithdrawRent({ dispatch, getters, state, commit }, { rentId }) {
            return new Promise((resolve, reject) => {
                client
                    .methods
                    .patch(`/v1/companies/${getters.userCompanyId}/rents/${rentId}/to-withdraw`)
                    .then(() => {
                        commit("showSuccessSnackbar", "Operação realizada com sucesso!")
                        dispatch("getRents", query(state));
                        resolve()
                    })
                    .catch(err => {
                        commit("showErrorSnackbar", err)
                        reject(err)
                    });
            })
        },
        placeRent({ dispatch, getters, state, commit }, { rentId, placingDate, devolutionDueDate }) {
            return new Promise((resolve, reject) => {
                client
                    .methods
                    .patch(`/v1/companies/${getters.userCompanyId}/rents/${rentId}/place`,
                        {
                            placing_date: placingDate,
                            devolution_due_date: devolutionDueDate,
                        })
                    .then(() => {
                        commit("showSuccessSnackbar", "Operação realizada com sucesso!")
                        dispatch("getRents", query(state));
                        resolve()
                    })
                    .catch(err => {
                        commit("showErrorSnackbar", err)
                        reject(err)
                    });
            })
        },
        createRent({ dispatch, getters, state, commit }, data) {
            return new Promise((resolve, reject) => {
                client
                    .methods
                    .post(`/v1/companies/${getters.userCompanyId}/rents`,
                        {
                            payment_date: moment().tz("America/Sao_Paulo").format('YYYY-MM-DD'),
                            client_id: data.clientId,
                            postal_code: data.postalCode,
                            street_name: data.streetName,
                            street_number: data.streetNumber,
                            secondary_address: data.secondaryAddress,
                            district: data.district,
                            city: data.city,
                            state_abbr: data.stateAbbr,
                            payment_status_id: data.paymentStatusId,
                            rent_status_id: data.rentStatusId,
                            dumpster_id: data.dumpsterId,
                            placing_date: data.placingDate,
                            devolution_due_date: data.devolutionDueDate,
                            price: parseFloat(data.price),
                        })
                    .then(() => {
                        commit("showSuccessSnackbar", "Operação realizada com sucesso!")
                        dispatch("getRents", query(state));
                        resolve()
                    })
                    .catch(err => {
                        commit("showErrorSnackbar", err.response.data.errors || err.response.data.message)
                        reject(err)
                    });
            })
        },
        setDumpster({ dispatch, getters, state, commit }, { rentId, dumpsterId }) {
            return new Promise((resolve, reject) => {
                client
                    .methods
                    .patch(`/v1/companies/${getters.userCompanyId}/rents/${rentId}`,
                        {
                            dumpster_id: dumpsterId,
                        })
                    .then(() => {
                        commit("showSuccessSnackbar", "Operação realizada com sucesso!")
                        dispatch("getRents", query(state));
                        resolve()
                    })
                    .catch(err => {
                        commit("showErrorSnackbar", err)
                        reject(err)
                    });
            })
        },
    },
    getters: {
        allRents: (state) => state.rents,
        paginationCurrentPageRents: (state) => state.pagination.currentPage,
        paginationTotalPagesRents: (state) => state.pagination.totalPages,
        paginationPerPageRents: (state) => state.pagination.perPage,
        rentsByClientName: (state) => state.search.rentsByClientName,
        rentsByStreetName: (state) => state.search.rentsByStreetName,
    }
}
