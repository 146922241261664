import Vue from 'vue'
import Vuex from 'vuex'
import auth from '@/store/modules/auth'
import password from '@/store/modules/password'
import snackbar from '@/store/modules/snackbar'
import rents from '@/store/modules/rents'
import loading from '@/store/modules/loading'
import addresses from '@/store/modules/addresses'
import dumpsters from '@/store/modules/dumpsters'
import clients from '@/store/modules/clients'
import account from '@/store/modules/account'
import metrics from '@/store/modules/metrics'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    auth,
    snackbar,
    rents,
    loading,
    addresses,
    dumpsters,
    clients,
    password,
    account,
    metrics,
  }
})
