<template>
  <v-dialog value="true" persistent max-width="500px">
    <ValidationObserver v-slot="{ invalid, validated, handleSubmit }">
      <v-card>
        <v-progress-linear
          indeterminate
          color="primary"
          absolute
          :active="loading"
        ></v-progress-linear>
        <v-card-title>
          <span class="text-h5">Cancelar Locação Nº {{ item.public_id }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <ValidationProvider
                  name="motivo do cancelamento"
                  rules="required"
                  v-slot="{ errors, valid }"
                >
                  <v-textarea
                    v-model="cancelReason"
                    label="Motivo do cancelamento"
                    :error-messages="errors"
                    :success="valid"
                  ></v-textarea>
                </ValidationProvider>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="$emit('close')">
            Fechar
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="handleSubmit(cancel)"
            :disabled="invalid || !validated"
          >
            Salvar
          </v-btn>
        </v-card-actions>
      </v-card>
    </ValidationObserver>
  </v-dialog>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
export default {
  props: ["item"],
  data() {
    return {
      cancelReason: "",
      loading: false,
    };
  },
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  methods: {
    cancel() {
      this.loading = true;

      this.$store
        .dispatch("cancelRent", {
          rentId: this.item.id,
          cancelReason: this.cancelReason,
        })
        .then(() => {
          this.loading = false;
          this.$emit("close");
        })
        .catch(() => {
          this.loading = false;
          this.$emit("close");
        });
    },
  },
};
</script>

<style>
</style>
