import client from '../../mixins/api/client'

export default {
    state: {
        include: 'client',
        search: {
            addressesByClientName: [],
            addressesByStreetName: [],
        },
    },
    mutations: {
        setAddressSearchByClientName(state, { response: { data: { data } } }) {
            state.search.addressesByClientName = data;
        },
        setAddressSearchByStreetName(state, { response: { data: { data } } }) {
            state.search.addressesByStreetName = data;
        }
    },
    actions: {
        searchAddressesByClientName({ commit, getters, state }, query) {
            const { clientName = null } = query;

            return new Promise((resolve, reject) => {
                client
                    .methods
                    .get(`/v1/companies/${getters.userCompanyId}/addresses`, {
                        params: {
                            include: state.include,
                            'filter[client.name]': clientName,
                        }
                    })
                    .then(response => {
                        commit('setAddressSearchByClientName', { response })
                        resolve()
                    })
                    .catch(err => {
                        commit("showErrorSnackbar", err)
                        reject(err)
                    });
            })
        },
        searchAddressesByStreetName({ commit, getters, state }, query) {
            const { streetName = null } = query;

            return new Promise((resolve, reject) => {
                client
                    .methods
                    .get(`/v1/companies/${getters.userCompanyId}/addresses`, {
                        params: {
                            include: state.include,
                            'filter[street_name]': streetName,
                        }
                    })
                    .then(response => {
                        commit('setAddressSearchByStreetName', { response })
                        resolve()
                    })
                    .catch(err => {
                        commit("showErrorSnackbar", err)
                        reject(err)
                    });
            })
        },
    },
    getters: {
        addressesByClientName: (state) => state.search.addressesByClientName,
        addressesByStreetName: (state) => state.search.addressesByStreetName,
    }
}
