const methods = {
    parseAttributeFromRoute(router, attribute) {
        const values = router.query[attribute]
            ? router.query[attribute].split(",")
            : [];

        const result = values.filter(function (value) {
            return value != null;
        })

        return result;
    },

    parseListToInt(list) {
        const result = list.map(function (value) {
            return parseInt(value, 10);
        });

        return result;
    },
}
export default {
    methods
};
