import instance from './instance';

export default {
    methods: {
        get(url, config = {}) { return instance.get(url, config); },
        post(url, data = {}, config = {}) { return instance.post(url, data, config = {}); },
        put(url, data) { return instance.put(url, data); },
        patch(url, data) { return instance.patch(url, data); },
        delete(url) { return instance.delete(url); },
    },
};
