import client from '../../mixins/api/client'

function initialState() {
    return {
        clients: [],
        pagination: {
            currentPage: 1,
            totalPages: 0,
            perPage: 10,
        },
        filter: {
            clientId: null,
        },
        search: {
            clientsByClientName: [],
            clientsByStreetName: [],
        },
        include: 'addresses'
    }
}

function query(state) {
    return {
        pageNumber: state.pagination.currentPage,
        clientId: state.filter.clientId,
    }
}

export default {
    state: initialState,
    mutations: {
        setClients(state, { query: query, response: { data: { data, meta: { pagination } } } }) {
            state.clients = data;
            state.pagination.currentPage = pagination.current_page;
            state.pagination.totalPages = pagination.total_pages;
            state.pagination.perPage = pagination.per_page;
            state.filter.clientId = query.clientId;
        },
        resetClients(state) {
            const s = initialState()
            Object.keys(s).forEach(key => {
                state[key] = s[key]
            })
        },
        setSearchByClientName(state, { response: { data: { data } } }) {
            state.search.clientsByClientName = data;
        },
    },
    actions: {
        getClients({ commit, getters, state }, query) {
            commit("showLoading");

            const {
                pageNumber = 1,
                clientId = null,
                streetName = null
            } = query;

            return new Promise((resolve, reject) => {
                client
                    .methods
                    .get(`/v1/companies/${getters.userCompanyId}/clients`, {
                        params: {
                            include: state.include,
                            'page[size]': state.pagination.perPage,
                            'page[number]': pageNumber,
                            'filter[id]': clientId,
                            'filter[addresses.street_name]': streetName,
                        }
                    })
                    .then(response => {
                        commit("hideLoading");
                        commit('setClients', { response, query })
                        resolve()
                    })
                    .catch(err => {
                        commit("hideLoading");
                        commit("showErrorSnackbar", err)
                        reject(err)
                    });
            })
        },
        createClient({ dispatch, getters, commit, state }, data) {
            return new Promise((resolve, reject) => {
                client
                    .methods
                    .post(`/v1/companies/${getters.userCompanyId}/clients`,
                        {
                            name: data.name,
                            cpf_cnpj: data.cpfCnpj,
                            cell: data.cell,
                            phone: data.phone,
                        })
                    .then(({ data: { id } }) => {
                        return client
                            .methods
                            .post(`/v1/companies/${getters.userCompanyId}/clients/${id}/addresses`,
                                {
                                    postal_code: data.postalCode,
                                    street_number: data.streetNumber,
                                    street_name: data.streetName,
                                    district: data.district,
                                    city: data.city,
                                    secondary_address: data.secondaryAddress,
                                    address_reference_point: data.addressReferencePoint,
                                    state_abbr: data.stateAbbr,
                                }).then(() => {
                                    commit("showSuccessSnackbar", "Operação realizada com sucesso!")
                                    dispatch("getClients", query(state));
                                    resolve()
                                }).catch(err => {
                                    commit("showErrorSnackbar", err.response.data.errors || err.response.data.message)
                                    reject(err)
                                });
                    })
                    .catch(err => {
                        commit("showErrorSnackbar", err.response.data.errors || err.response.data.message)
                        reject(err)
                    });
            })
        },
        updateClient({ dispatch, getters, commit, state }, data) {
            return new Promise((resolve, reject) => {
                client
                    .methods
                    .patch(`/v1/companies/${getters.userCompanyId}/clients/${data.clientId}`,
                        {
                            name: data.name,
                            cpf_cnpj: data.cpfCnpj,
                            cell: data.cell,
                            phone: data.phone,
                        })
                    .then(({ data: { id } }) => {
                        const method = data.addressId ? 'patch' : 'post';
                        const addressId = data.addressId ? data.addressId : '';

                        return client
                            .methods[method](`/v1/companies/${getters.userCompanyId}/clients/${id}/addresses/${addressId}`,
                                {
                                    postal_code: data.postalCode,
                                    street_number: data.streetNumber,
                                    street_name: data.streetName,
                                    district: data.district,
                                    city: data.city,
                                    secondary_address: data.secondaryAddress,
                                    address_reference_point: data.addressReferencePoint,
                                    state_abbr: data.stateAbbr,
                                }).then(() => {
                                    commit("showSuccessSnackbar", "Operação realizada com sucesso!")
                                    dispatch("getClients", query(state));
                                    resolve()
                                }).catch(err => {
                                    commit("showErrorSnackbar", err.response.data.errors || err.response.data.message)
                                    reject(err)
                                });
                    })
                    .catch(err => {
                        commit("showErrorSnackbar", err.response.data.errors || err.response.data.message)
                        reject(err)
                    });
            })
        },
        searchClientsByClientName({ commit, getters, state }, query) {
            const { clientName = null } = query;

            return new Promise((resolve, reject) => {
                client
                    .methods
                    .get(`/v1/companies/${getters.userCompanyId}/clients`, {
                        params: {
                            include: state.include,
                            'filter[name]': clientName,
                        }
                    })
                    .then(response => {
                        commit('setSearchByClientName', { response })
                        resolve()
                    })
                    .catch(err => {
                        commit("showErrorSnackbar", err)
                        reject(err)
                    });
            })
        },
    },
    getters: {
        allClients: (state) => state.clients,
        paginationCurrentPageClients: (state) => state.pagination.currentPage,
        paginationTotalPagesClients: (state) => state.pagination.totalPages,
        paginationPerPageClients: (state) => state.pagination.perPage,
        clientsByClientName: (state) => state.search.clientsByClientName,
        clientsByStreetName: (state) => state.search.clientsByStreetName,
    }
}
