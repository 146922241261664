var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-expansion-panels',{attrs:{"flat":"","tile":""},model:{value:(_vm.panel),callback:function ($$v) {_vm.panel=$$v},expression:"panel"}},[_c('v-expansion-panel',[_c('v-expansion-panel-header',{attrs:{"color":"grey lighten-3"}},[_c('div',[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"dark":"","color":"grey darken-1"}},'v-icon',attrs,false),on),[_vm._v(" mdi-filter ")])]}}])},[_c('span',[_vm._v("Filtrar clientes")])])],1)]),_c('v-expansion-panel-content',[_c('v-container',{attrs:{"fluid":""}},[_c('ValidationObserver',{ref:"observer"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-autocomplete',{attrs:{"items":_vm.searchByClientNameItems,"loading":_vm.isLoadingByClientName,"search-input":_vm.searchClientsByClientName,"small-chips":"","clearable":"","hide-details":"","return-object":"","itemid":"id","item-text":"name","item-value":"id","label":"Nome do cliente","no-data-text":"Nenhum dado encontrado"},on:{"change":_vm.filter,"update:searchInput":function($event){_vm.searchClientsByClientName=$event},"update:search-input":function($event){_vm.searchClientsByClientName=$event}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.name)}}),_c('v-list-item-subtitle',{domProps:{"textContent":_vm._s(_vm.formatAddress(item))}})],1)]}}]),model:{value:(_vm.clientByClientName),callback:function ($$v) {_vm.clientByClientName=$$v},expression:"clientByClientName"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-autocomplete',{attrs:{"items":_vm.searchByStreetNameItems,"loading":_vm.isLoadingByStreetName,"search-input":_vm.searchClientsByStreetName,"small-chips":"","clearable":"","hide-details":"","return-object":"","itemid":"street_name","item-text":"street_name","item-value":"street_name","label":"Endereço do cliente","no-data-text":"Nenhum dado encontrado"},on:{"change":_vm.filter,"update:searchInput":function($event){_vm.searchClientsByStreetName=$event},"update:search-input":function($event){_vm.searchClientsByStreetName=$event}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.street_name)}})],1)]}}]),model:{value:(_vm.clientByStreetName),callback:function ($$v) {_vm.clientByStreetName=$$v},expression:"clientByStreetName"}})],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }