<template>
  <div>
    <v-menu bottom left transition="slide-y-transition" v-if="menuEnabled">
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon v-bind="attrs" v-on="on">
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>
      </template>

      <v-list>
        <v-list-item v-if="isWithdrawPlaceable" @click="dialogs.place = true">
          <v-list-item-title>Colocar</v-list-item-title>
        </v-list-item>
        <v-list-item v-if="isDumpsterSetable" @click="dialogs.setDumpster = true">
          <v-list-item-title>Selecionar Caçamba</v-list-item-title>
        </v-list-item>
        <v-list-item
          v-if="isWithdrawSchedulable"
          @click="dialogs.scheduleWithdraw = true"
        >
          <v-list-item-title>Agendar Retirada</v-list-item-title>
        </v-list-item>
        <v-list-item v-if="isWithdrawable" @click="dialogs.withdraw = true">
          <v-list-item-title>Retirar</v-list-item-title>
        </v-list-item>
        <v-list-item v-if="isPayable" @click="dialogs.pay = true">
          <v-list-item-title>Confirmar Pagamento</v-list-item-title>
        </v-list-item>
        <v-list-item v-if="isCancelable" @click="dialogs.cancel = true">
          <v-list-item-title>Cancelar</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>

    <place-rent
      :item="item"
      v-if="dialogs.place"
      @close="dialogs.place = false"
    />

    <schedule-withdraw-rent
      :item="item"
      v-if="dialogs.scheduleWithdraw"
      @close="dialogs.scheduleWithdraw = false"
    />

    <withdraw-rent
      :item="item"
      v-if="dialogs.withdraw"
      @close="dialogs.withdraw = false"
    />

    <pay-rent :item="item" v-if="dialogs.pay" @close="dialogs.pay = false" />

    <cancel-rent
      :item="item"
      v-if="dialogs.cancel"
      @close="dialogs.cancel = false"
    />

    <set-dumpster
      :item="item"
      v-if="dialogs.setDumpster"
      @close="dialogs.setDumpster = false"
    />
  </div>
</template>

<script>
import CancelRent from "./dialogs/CancelRent";
import PayRent from "./dialogs/PayRent";
import WithdrawRent from "./dialogs/WithdrawRent";
import ScheduleWithdrawRent from "./dialogs/ScheduleWithdrawRent";
import PlaceRent from "./dialogs/PlaceRent";
import SetDumpster from "./dialogs/SetDumpster";
import status from "./../../mixins/rent/status";
import payment from "./../../mixins/rent/payment";

export default {
  props: ["item"],
  components: {
    CancelRent,
    PayRent,
    WithdrawRent,
    ScheduleWithdrawRent,
    PlaceRent,
    SetDumpster,
  },
  data: () => ({
    dialogs: {
      cancel: false,
      pay: false,
      withdraw: false,
      scheduleWithdraw: false,
      place: false,
      setDumpster: false,
    },
  }),
  computed: {
    isCancelable() {
      return ![status.CANCELED, status.WITHDRAWN].includes(
        this.item.rent_status.id
      );
    },
    isPayable() {
      return (
        this.item.payment_status.id == payment.WAITING_PAYMENT &&
        !status.methods.canceled(this.item)
      );
    },
    isWithdrawable() {
      return [status.PLACED, status.TO_WITHDRAW].includes(
        this.item.rent_status.id
      );
    },
    isWithdrawSchedulable() {
      return this.item.rent_status.id == status.PLACED;
    },
    isWithdrawPlaceable() {
      return this.item.rent_status.id == status.TO_PLACE;
    },
    isDumpsterSetable() {
      return !this.item.dumpster && !status.methods.canceled(this.item);
    },
    menuEnabled() {
      return (
        this.isCancelable ||
        this.isPayable ||
        this.isWithdrawable ||
        this.isWithdrawSchedulable ||
        this.isWithdrawPlaceable ||
        this.isDumpsterSetable
      );
    },
  },
};
</script>

<style>
</style>
