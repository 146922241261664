<template>
  <div>
    <create-client
      v-if="dialogs.createClient"
      @close="dialogs.createClient = false"
    />
    
    <v-data-table
      :headers="headers"
      :header-props="headerProps"
      :items="clients"
      :page.sync="page"
      :items-per-page="itemsPerPage"
      hide-default-footer
      show-expand
      single-expand
      item-key="id"
      no-data-text="Nenhum dado encontrado"
    >
      <template v-slot:top>
        <client-filter />
        <v-toolbar flat>
          <v-btn
            color="pink"
            fab
            x-small
            dark
            @click="dialogs.createClient = true"
          >
            <v-icon dark> mdi-plus </v-icon>
          </v-btn>
        </v-toolbar>
      </template>

      <template v-slot:expanded-item="{ headers, item }">
        <client-expanded-info :headers="headers" :item="item" />
      </template>

      <template v-slot:item.address="{ item }">
        <client-address :item="item" />
      </template>

      <template v-slot:item.actions="{ item }">
        <client-menu :item="item" />
      </template>
    </v-data-table>
    <div class="text-center">
      <v-divider></v-divider>
      <v-container>
        <v-row justify="center">
          <v-col>
            <v-container class="max-width">
              <v-pagination
                v-model="page"
                :length="pageCount"
                :total-visible="7"
              ></v-pagination>
            </v-container>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ClientExpandedInfo from "./ClientExpandedInfo";
import ClientFilter from "./ClientFilter";
import CreateClient from "./dialogs/CreateClient";
import ClientAddress from "./ClientAddress";
import ClientMenu from "./ClientMenu";

export default {
  data() {
    return {
      dialogs: {
        createClient: false,
      },
      page: parseInt(this.$route.query.page) || 1,
      headerProps: {
        sortByText: "Ordenar por",
      },
      headers: [
        { text: "Cliente", value: "name", sortable: false },
        { text: "Endereço", value: "address", sortable: false },
        { value: "actions", sortable: false },
      ],
    };
  },
  components: {
    ClientExpandedInfo,
    ClientFilter,
    CreateClient,
    ClientAddress,
    ClientMenu,
  },
  watch: {
    "$route.path": {
      immediate: true,
      handler(to, from) {
        this.$store.commit("resetClients");
      },
    },
    "$route.query.page": function (value) {
      const number = parseInt(value);
      if (!isNaN(number)) {
        this.page = number;
      }
    },
    page(value) {
      const query = { ...this.$route.query, page: value };
      this.$router.replace({ query }, () => {});
      this.getClients(value);
    },
  },
  computed: {
    ...mapGetters({
      totalPages: "paginationTotalPagesClients",
      clients: "allClients",
      itemsPerPage: "paginationPerPageClients",
      pageCount: "paginationTotalPagesClients",
    }),
  },
  methods: {
    getClients(page) {
      this.$store.dispatch("getClients", {
        pageNumber: page,
        clientId: this.$route.query.clientId || null,
        clientName: this.$route.query.clientName || null,
        streetName: this.$route.query.streetName || null,
      });
    },
  },
  mounted() {
    this.getClients(this.page);
  },
};
</script>

<style>
</style>
