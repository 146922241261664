var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"value":"true","persistent":"","max-width":"500px"}},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var handleSubmit = ref.handleSubmit;
return [_c('v-card',[_c('v-progress-linear',{attrs:{"indeterminate":"","color":"primary","absolute":"","active":_vm.loading}}),_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v("Nova Caçamba")])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('ValidationProvider',{attrs:{"name":"número","rules":"required|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Número","error-messages":errors},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return handleSubmit(_vm.create)}},model:{value:(_vm.number),callback:function ($$v) {_vm.number=$$v},expression:"number"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('ValidationProvider',{attrs:{"name":"volume","rules":"numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Volume m³","error-messages":errors},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return handleSubmit(_vm.create)}},model:{value:(_vm.volume),callback:function ($$v) {_vm.volume=$$v},expression:"volume"}})]}}],null,true)})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v(" Fechar ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":"","disabled":invalid},on:{"click":function($event){return handleSubmit(_vm.create)}}},[_vm._v(" Salvar ")])],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }