import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'
import Reset from '../views/Reset.vue'
import Rents from '../views/Rents.vue'
import Clients from '../views/Clients.vue'
import Dumpsters from '../views/Dumpsters.vue'
import Metrics from '../views/Metrics.vue'
import store from '@/store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      name: 'Gerencie Caçambas'
    }
  },
  {
    path: '/reset-password',
    name: 'Reset',
    component: Reset,
    meta: {
      name: 'Gerencie Caçambas'
    }
  },
  {
    path: '/rents',
    name: 'Rents',
    component: Rents,
    meta: {
      requiresAuth: true,
      name: 'Locações'
    }
  },
  {
    path: '/clients',
    name: 'Clients',
    component: Clients,
    meta: {
      requiresAuth: true,
      name: 'Clientes'
    }
  },
  {
    path: '/dumpsters',
    name: 'Dumpsters',
    component: Dumpsters,
    meta: {
      requiresAuth: true,
      name: 'Caçambas'
    }
  },
  {
    path: '/metrics',
    name: 'Metrics',
    component: Metrics,
    meta: {
      requiresAuth: true,
      name: 'Métricas'
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!store.getters.userLogged) {
      next({ name: 'Login' })
    } else {
      next()
    }
  } else {
    next()
  }
})

export default router
