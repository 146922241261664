<template>
  <v-app id="inspire">
    <navigation />

    <v-main>
      <router-view />
      <snackbar />
    </v-main>
    <v-btn fixed color="success" small fab dark bottom left href="#" target="_blank">
      <v-icon>mdi-whatsapp</v-icon>
    </v-btn>
  </v-app>
</template>

<script>
import apiConfig from "@/mixins/api/config";
import Navigation from "@/components/header/Navigation.vue";
import Snackbar from "@/components/feedback/Snackbar.vue";
import auth from "@/mixins/auth";

export default {
  components: { Navigation, Snackbar },
  mixins: [apiConfig, auth],
  created() {
    this.interceptors();

    if (
      this.getTokenWithExpiry(auth.TOKEN) &&
      localStorage.getItem(auth.COMPANY_ID)
    ) {
      this.$store.commit("setAccessToken", this.getTokenWithExpiry(auth.TOKEN));
      this.$store.commit("setCompanyId", localStorage.getItem(auth.COMPANY_ID));
    } else {
      if (!["/reset-password", "/forgot-password"].includes(this.$route.path)) {
        this.$router.push({ name: "Login" }, () => {});
      }
    }
  },
  data() {
    return {
      title: process.env.VUE_APP_TITLE,
    };
  },
};
</script>
