var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.dialogs.createRent)?_c('create-rent',{on:{"close":function($event){_vm.dialogs.createRent = false}}}):_vm._e(),(_vm.dialogs.createClient)?_c('create-client',{on:{"close":function($event){_vm.dialogs.createClient = false}}}):_vm._e(),(_vm.dialogs.createDumpster)?_c('create-dumpster',{on:{"close":function($event){_vm.dialogs.createDumpster = false}}}):_vm._e(),_c('v-data-table',{attrs:{"headers":_vm.headers,"header-props":_vm.headerProps,"items":_vm.rents,"page":_vm.page,"items-per-page":_vm.itemsPerPage,"hide-default-footer":"","show-expand":"","single-expand":"","item-key":"id","no-data-text":"Nenhum dado encontrado"},on:{"update:page":function($event){_vm.page=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('rent-filter'),_c('v-toolbar',{attrs:{"flat":""}},[_c('v-speed-dial',{attrs:{"bottom":"","left":"","absolute":"","direction":"right"},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-btn',{attrs:{"color":"pink","fab":"","x-small":"","dark":""}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-plus ")])],1)]},proxy:true}])},[_c('v-btn',{attrs:{"x-small":"","color":"primary"},on:{"click":function($event){_vm.dialogs.createRent = true}}},[_vm._v(" Locação")]),_c('v-btn',{attrs:{"x-small":"","color":"primary"},on:{"click":function($event){_vm.dialogs.createClient = true}}},[_vm._v(" Cliente")]),_c('v-btn',{attrs:{"x-small":"","color":"primary"},on:{"click":function($event){_vm.dialogs.createDumpster = true}}},[_vm._v(" Caçamba")])],1)],1)]},proxy:true},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('rent-expanded-info',{attrs:{"headers":headers,"item":item}})]}},{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('rent-id',{attrs:{"item":item}})]}},{key:"item.client",fn:function(ref){
var item = ref.item;
return [_c('rent-client',{attrs:{"item":item}})]}},{key:"item.address",fn:function(ref){
var item = ref.item;
return [_c('rent-address',{attrs:{"item":item}})]}},{key:"item.price",fn:function(ref){
var item = ref.item;
return [_c('rent-price',{attrs:{"item":item}})]}},{key:"item.situation",fn:function(ref){
var item = ref.item;
return [_c('rent-status',{attrs:{"item":item}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('rent-menu',{attrs:{"item":item}})]}}])}),_c('div',{staticClass:"text-center"},[_c('v-divider'),_c('v-container',[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',[_c('v-container',{staticClass:"max-width"},[_c('v-pagination',{attrs:{"length":_vm.pageCount,"total-visible":7},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }