<template>
  <div>
    <v-app-bar app color="primary" dark class="elevation-0">
      <v-app-bar-nav-icon
        v-if="showMenu"
        @click="drawerToggle"
      ></v-app-bar-nav-icon>

      <v-toolbar-title>{{ $route.meta.name }}</v-toolbar-title>
      <loading />
    </v-app-bar>

    <v-navigation-drawer v-if="showMenu" v-model="drawer" app temporary>
      <template v-slot:prepend>
        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title>{{ title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>

      <v-divider></v-divider>
      <v-list dense nav>
        <v-list-item-group
          v-model="group"
          active-class="primary--text text--accent-4"
        >
          <v-list-item
            v-for="item in items"
            :key="item.title"
            :to="item.to"
            link
          >
            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>

      <template v-slot:append>
        <div class="pa-2">
          <v-btn block color="primary" @click="logout"> Sair </v-btn>
          <v-card-text class="text--disabled text-caption text-center">
            {{ new Date().getFullYear() }} - {{ title }}
          </v-card-text>
        </div>
      </template>
    </v-navigation-drawer>
  </div>
</template>

<script>
import Loading from "@/components/feedback/Loading.vue";

export default {
  components: { Loading },
  data() {
    return {
      drawer: false,
      group: null,
      items: [
        { title: "Locações", to: "/rents" },
        { title: "Clientes", to: "/clients" },
        { title: "Caçambas", to: "/dumpsters" },
        { title: "Métricas", to: "/metrics" },
      ],
      title: process.env.VUE_APP_TITLE,
    };
  },
  computed: {
    showMenu() {
      return !["/login", "/reset-password", "/forgot-password"].includes(
        this.$route.path
      );
    },
  },
  methods: {
    logout: function () {
      this.$store.dispatch("logout").then(() => {
        this.drawerToggle();
        this.$router.push({ name: "Login" });
      });
    },
    drawerToggle: function () {
      this.drawer = !this.drawer;
    },
  },
};
</script>

<style>
</style>
