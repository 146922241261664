var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"value":"true","persistent":"","max-width":"500px"}},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var validated = ref.validated;
var handleSubmit = ref.handleSubmit;
return [_c('v-card',[_c('v-progress-linear',{attrs:{"indeterminate":"","color":"primary","absolute":"","active":_vm.loading}}),_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v("Nova Locação")])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":"cliente","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"items":_vm.searchByClientNameItems,"loading":_vm.isLoadingByClientName,"search-input":_vm.searchRentsByClientName,"small-chips":"","hide-details":"","return-object":"","itemid":"client.id","item-text":"client.name","item-value":"client.id","label":"Nome do cliente","no-data-text":"Nenhum dado encontrado","error-messages":errors},on:{"update:searchInput":function($event){_vm.searchRentsByClientName=$event},"update:search-input":function($event){_vm.searchRentsByClientName=$event}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.client.name)}}),_c('v-list-item-subtitle',{domProps:{"textContent":_vm._s(_vm.parseAddress(item))}})],1)]}}],null,true),model:{value:(_vm.address),callback:function ($$v) {_vm.address=$$v},expression:"address"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('ValidationProvider',{attrs:{"name":"situação do pagamento","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.paymentStatuses,"item-value":"id","item-text":"name","small-chips":"","label":"Situação do pagamento","error-messages":errors},model:{value:(_vm.paymentStatusId),callback:function ($$v) {_vm.paymentStatusId=$$v},expression:"paymentStatusId"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('ValidationProvider',{attrs:{"name":"situação da locação","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.rentStatuses,"item-value":"id","item-text":"name","small-chips":"","label":"Situação da locação","error-messages":errors},model:{value:(_vm.rentStatusId),callback:function ($$v) {_vm.rentStatusId=$$v},expression:"rentStatusId"}})]}}],null,true)})],1),(_vm.rentDatesRequired)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","max-width":"290px","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('ValidationProvider',{attrs:{"name":"data de colocação","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',_vm._g(_vm._b({key:"placing-date",attrs:{"label":"Data de colocação","persistent-hint":"","readonly":"","error-messages":errors},model:{value:(_vm.computedPlacingDateFormatted),callback:function ($$v) {_vm.computedPlacingDateFormatted=$$v},expression:"computedPlacingDateFormatted"}},'v-text-field',attrs,false),on))]}}],null,true)})]}}],null,true),model:{value:(_vm.placingMenu),callback:function ($$v) {_vm.placingMenu=$$v},expression:"placingMenu"}},[_c('v-date-picker',{attrs:{"no-title":"","locale":"pt-br","color":"primary","max":_vm.maxPlacingDate},on:{"input":function($event){_vm.placingMenu = false}},model:{value:(_vm.placingDate),callback:function ($$v) {_vm.placingDate=$$v},expression:"placingDate"}})],1)],1):_vm._e(),(_vm.rentDatesRequired)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","max-width":"290px","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('ValidationProvider',{attrs:{"name":"previsão de retirada","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',_vm._g(_vm._b({key:"devolution-due-date",attrs:{"label":"Previsão de retirada","persistent-hint":"","readonly":"","error-messages":errors},model:{value:(_vm.computedDevolutionDueDateFormatted),callback:function ($$v) {_vm.computedDevolutionDueDateFormatted=$$v},expression:"computedDevolutionDueDateFormatted"}},'v-text-field',attrs,false),on))]}}],null,true)})]}}],null,true),model:{value:(_vm.devolutionMenu),callback:function ($$v) {_vm.devolutionMenu=$$v},expression:"devolutionMenu"}},[_c('v-date-picker',{attrs:{"no-title":"","locale":"pt-br","min":_vm.placingDate,"color":"primary"},on:{"input":function($event){_vm.devolutionMenu = false}},model:{value:(_vm.devolutionDueDate),callback:function ($$v) {_vm.devolutionDueDate=$$v},expression:"devolutionDueDate"}})],1)],1):_vm._e(),_c('v-col',{attrs:{"cols":"6"}},[_c('ValidationProvider',{attrs:{"name":"valor","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vuetify-money',{key:"price",attrs:{"label":"Valor","valueWhenIsEmpty":_vm.valueWhenIsEmpty,"options":_vm.options,"error-messages":errors},model:{value:(_vm.price),callback:function ($$v) {_vm.price=$$v},expression:"price"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-autocomplete',{attrs:{"items":_vm.dumpstersByAvailability,"small-chips":"","hide-details":"","itemid":"id","item-text":"number","item-value":"id","label":"Caçamba","no-data-text":"Nenhum dado encontrado","clearable":""},model:{value:(_vm.dumpsterId),callback:function ($$v) {_vm.dumpsterId=$$v},expression:"dumpsterId"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v(" Fechar ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":"","disabled":invalid || !validated},on:{"click":function($event){return handleSubmit(_vm.create)}}},[_vm._v(" Salvar ")])],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }