import client from '../../mixins/api/client'
import auth from '../../mixins/auth'

export default {
    state: {
    },
    mutations: {
    },
    actions: {
        createAccount({ dispatch }, data) {
            return dispatch("createUser", {
                name: data.name,
                email: data.email,
                password: data.password,
            }).then(() => {
                return dispatch("login", {
                    email: data.email,
                    password: data.password,
                });
            }).then(() => {
                return dispatch("createCompany", {
                    name: data.name,
                });
            });
        },
        createUser({ commit }, data) {
            return new Promise((resolve, reject) => {
                client
                    .methods
                    .post(`/v1/auth/register`,
                        {
                            name: data.name,
                            email: data.email,
                            password: data.password,
                        })
                    .then(() => {
                        resolve()
                    })
                    .catch(err => {
                        commit("showErrorSnackbar", err.response.data.errors || err.response.data.message)
                        reject(err)
                    });
            })
        },
        createCompany({ commit }, { name }) {
            return new Promise((resolve, reject) => {
                client
                    .methods
                    .post("/v1/companies", {
                        name: name,
                    }, {
                        'Authorization': `Bearer ${auth.methods.getTokenWithExpiry(auth.TOKEN)}`
                    })
                    .then(({ data: { id } }) => {
                        localStorage.setItem(auth.COMPANY_ID, id)
                        commit("setCompanyId", id);
                        commit("showSuccessSnackbar", "Operação realizada com sucesso!")
                        resolve()
                    })
                    .catch(err => {
                        commit(
                            "showErrorSnackbar",
                            err.response.data.errors || err.response.data.message,
                        )
                        reject(err)
                    });
            })
        }
    },
    getters: {
    }
}
