var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"value":"true","persistent":"","max-width":"500px"}},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var validated = ref.validated;
var handleSubmit = ref.handleSubmit;
return [_c('v-card',[_c('v-progress-linear',{attrs:{"indeterminate":"","color":"primary","absolute":"","active":_vm.loading}}),_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v("Cancelar Locação Nº "+_vm._s(_vm.item.public_id))])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":"motivo do cancelamento","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-textarea',{attrs:{"label":"Motivo do cancelamento","error-messages":errors,"success":valid},model:{value:(_vm.cancelReason),callback:function ($$v) {_vm.cancelReason=$$v},expression:"cancelReason"}})]}}],null,true)})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v(" Fechar ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":"","disabled":invalid || !validated},on:{"click":function($event){return handleSubmit(_vm.cancel)}}},[_vm._v(" Salvar ")])],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }