<template>
  <v-tooltip right :disabled="isCanceled">
    <template v-slot:activator="{ on, attrs }">
      <span
        v-bind="attrs"
        v-on="on"
        :class="{
          'text--disabled text-decoration-line-through': isCanceled,
          'text--disabled': waitingPaiment,
          'text--primary': paid,
        }"
        >{{ price }}</span
      >
    </template>
    <span> {{ this.item.payment_status.name }}</span>
  </v-tooltip>
</template>

<script>
import payment from "@/mixins/rent/payment";
import status from "@/mixins/rent/status";

export default {
  props: ["item"],
  mixins: [status],
  computed: {
    price() {
      return parseFloat(this.item.price).toLocaleString("pt-BR", {
        style: "currency",
        currency: "BRL",
      });
    },
    isCanceled() {
      return this.canceled(this.item);
    },
    waitingPaiment() {
      return (
        !this.isCanceled &&
        this.item.payment_status.id == payment.WAITING_PAYMENT
      );
    },
    paid() {
      return !this.isCanceled && this.item.payment_status.id == payment.PAID;
    }
  },
};
</script>

<style>
</style>
