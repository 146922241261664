const TOKEN = 'access_token'
const COMPANY_ID = 'company_id'
const methods = {
    setTokenWithExpiry(key, value, ttl) {
        const now = new Date()
        const token = { value: value, expiry: now.getTime() + ttl }

        localStorage.setItem(key, JSON.stringify(token))
    },
    getTokenWithExpiry(key) {
        const item = localStorage.getItem(key)

        if (!item) {
            return null
        }

        const token = JSON.parse(item)
        const now = new Date()

        if (now.getTime() > token.expiry) {
            localStorage.removeItem(key)
            return null
        }

        return token.value
    }
}

export default {
    TOKEN,
    COMPANY_ID,
    methods
};
