<template>
  <span
    :class="{
      'text--disabled text-decoration-line-through': isCanceled,
    }"
    >{{ item.client.name }}</span
  >
</template>

<script>
import status from "@/mixins/rent/status";

export default {
  props: ["item"],
  mixins: [status],
  computed: {
    isCanceled() {
      return this.canceled(this.item);
    },
  },
};
</script>

<style>
</style>
