<template>
  <v-dialog value="true" persistent max-width="500px">
    <ValidationObserver v-slot="{ invalid, validated, handleSubmit }">
    <v-card>
      <v-progress-linear
        indeterminate
        color="primary"
        absolute
        :active="loading"
      ></v-progress-linear>
      <v-card-title>
        <span class="text-h5"
          >Selecionar Caçamba Locação Nº {{ item.public_id }}</span
        >
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12">
              <ValidationProvider
                name="caçamba"
                rules="required"
                v-slot="{ errors, valid }"
              >
                <v-autocomplete
                  v-model="dumpsterId"
                  :items="dumpsters"
                  small-chips
                  :error-messages="errors"
                  :success="valid"
                  itemid="id"
                  item-text="number"
                  item-value="id"
                  label="Caçamba"
                  no-data-text="Nenhum dado encontrado"
                >
                </v-autocomplete>
              </ValidationProvider>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="$emit('close')">
          Fechar
        </v-btn>
         <v-btn
            color="blue darken-1"
            text
            @click="handleSubmit(set)"
            :disabled="invalid || !validated"
          >
            Salvar
          </v-btn>
      </v-card-actions>
    </v-card>
    </ValidationObserver>
  </v-dialog>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import status from "@/mixins/rent/status";

export default {
  props: ["item"],
  mixins: [status],
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      loading: false,
      dumpsterId: null,
    };
  },
  mounted() {
    if (this.ongoing(this.item)) {
      this.$store.dispatch("searchDumpstersByAvailability");
    } else {
      this.$store.dispatch("getDumpstersList");
    }
  },
  computed: {
    dumpsters() {
      if (this.ongoing(this.item) && !this.canceled(this.item)) {
        return this.$store.getters.dumpstersByAvailability;
      }
      return this.$store.getters.dumpstersList;
    },
  },
  methods: {
    set() {
      this.loading = true;

      this.$store
        .dispatch("setDumpster", {
          rentId: this.item.id,
          dumpsterId: this.dumpsterId,
        })
        .then(() => {
          this.loading = false;
          this.$emit("close");
        })
        .catch(() => {
          this.loading = false;
          this.$emit("close");
        });
    },
  },
};
</script>

<style>
</style>
