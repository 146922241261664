<template>
  <v-dialog value="true" persistent max-width="600px">
    <ValidationObserver v-slot="{ invalid, handleSubmit }">
      <v-card>
        <v-progress-linear
          indeterminate
          color="primary"
          absolute
          :active="loading"
        ></v-progress-linear>
        <v-card-title>
          <span class="text-h5">Novo Cliente</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="6" md="6">
                <ValidationProvider
                  name="nome"
                  rules="required|min:3"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    label="Nome"
                    v-model="name"
                    :error-messages="errors"
                  ></v-text-field>
                </ValidationProvider>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <ValidationProvider
                  name="cpf/cnpj"
                  rules="cpfCnpj"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    label="CPF/CNPJ"
                    v-model="cpfCnpj"
                    :error-messages="errors"
                    v-mask="['###.###.###-##', '##.###.###/####-##']"
                  ></v-text-field>
                </ValidationProvider>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  label="Celular"
                  v-model="cell"
                  v-mask="['(##) ####-####', '(##) #####-####']"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  label="Telefone"
                  v-model="phone"
                  v-mask="['(##) ####-####']"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="3" md="3">
                <ValidationProvider
                  name="cep"
                  rules="required|length:9"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    label="CEP"
                    v-model="postalCode"
                    :error-messages="errors"
                    :loading="loadingCep"
                    @change="searchCep"
                    v-mask="['#####-###']"
                  ></v-text-field>
                </ValidationProvider>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <ValidationProvider
                  name="rua"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    label="Rua"
                    v-model="streetName"
                    :error-messages="errors"
                  ></v-text-field>
                </ValidationProvider>
              </v-col>
              <v-col cols="12" sm="3" md="3">
                <v-text-field
                  label="Número"
                  v-model="streetNumber"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  label="Complemento"
                  v-model="secondaryAddress"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  label="Ponto de referência"
                  v-model="addressReferencePoint"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="5" md="5">
                <ValidationProvider
                  name="bairro"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    label="Bairro"
                    v-model="district"
                    :error-messages="errors"
                  ></v-text-field>
                </ValidationProvider>
              </v-col>
              <v-col cols="12" sm="5" md="5">
                <ValidationProvider
                  name="cidade"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    label="Cidade"
                    v-model="city"
                    :error-messages="errors"
                  ></v-text-field>
                </ValidationProvider>
              </v-col>
              <v-col cols="12" sm="2" md="2">
                <ValidationProvider
                  name="estado"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    label="Estado"
                    v-model="stateAbbr"
                    :error-messages="errors"
                    v-mask="['AA']"
                  ></v-text-field>
                </ValidationProvider>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="$emit('close')">
            Fechar
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="handleSubmit(create)"
            :disabled="invalid"
          >
            Salvar
          </v-btn>
        </v-card-actions>
      </v-card>
    </ValidationObserver>
  </v-dialog>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { mask } from "vue-the-mask";

export default {
  data() {
    return {
      name: null,
      cpfCnpj: null,
      cell: null,
      phone: null,
      loading: false,
      postalCode: null,
      streetNumber: null,
      streetName: null,
      district: null,
      city: null,
      secondaryAddress: null,
      addressReferencePoint: null,
      stateAbbr: null,
      loadingCep: false,
    };
  },
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  directives: { mask },
  methods: {
    searchCep(value) {
      if (value.length === 9) {
        this.loadingCep = true;
        this.$viaCep.buscarCep(value).then((response) => {
          if (!response.erro) {
            this.streetName = response.logradouro;
            this.district = response.bairro;
            this.city = response.localidade;
            this.stateAbbr = response.uf;
          }

          this.loadingCep = false;
        });
      }
    },
    create() {
      this.loading = true;
      this.$store
        .dispatch("createClient", {
          name: this.name,
          cpfCnpj: this.cpfCnpj,
          cell: this.cell,
          phone: this.phone,
          postalCode: this.postalCode,
          streetNumber: this.streetNumber,
          streetName: this.streetName,
          district: this.district,
          city: this.city,
          secondaryAddress: this.secondaryAddress,
          addressReferencePoint: this.addressReferencePoint,
          stateAbbr: this.stateAbbr,
        })
        .then(() => {
          this.loading = false;
          this.$emit("close");
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style>
</style>
