<template>
  <list-clients />
</template>

<script>
import ListClients from "../components/client/ListClients";

export default {
  components: {
    ListClients,
  },
};
</script>
