export default {
    state: {
        text: '',
        color: '',
        show: false
    },
    mutations: {
        showErrorSnackbar(state, payload) {
            state.text = payload;
            state.color = 'error';
            state.show = true;
        },
        showSuccessSnackbar(state, payload) {
            state.text = payload;
            state.color = 'success';
            state.show = true;
        },
        hideSnackbar(state) {
            state.show = false
        }
    }
}
