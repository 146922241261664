import { cpf, cnpj } from 'cpf-cnpj-validator'

const validator = {
  validate (value, args) {
    return cpf.isValid(value) || cnpj.isValid(value)
  },
  message: 'CPF/CNPJ inválido'
}

export default validator
