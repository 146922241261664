<template>
  <span
    class="text-wrap"
    :class="{
      'text--disabled text-decoration-line-through': isCanceled,
    }"
    >{{ address }}</span
  >
</template>

<script>
import status from "@/mixins/rent/status";
import address from "@/mixins/rent/address";

export default {
  props: ["item"],
  mixins: [status, address],
  computed: {
    isCanceled() {
      return this.canceled(this.item);
    },
    address() {
      return this.parseAddress(this.item);
    },
  },
};
</script>

<style>
</style>
