import client from '../../mixins/api/client'

export default {
    state: {
    },
    mutations: {
    },
    actions: {
        resetPassword({ dispatch }, { token, password, passwordConfirmation, email }) {
            return dispatch("reset", {
                token: token,
                password: password,
                passwordConfirmation: passwordConfirmation,
                email: email,
            }).then(() => {
                return dispatch("login", {
                    email: email,
                    password: password,
                });
            });
        },
        reset({ commit }, { token, password, passwordConfirmation, email }) {
            commit("showLoading");
            return new Promise((resolve, reject) => {
                client
                    .methods
                    .post("/v1/reset-password", {
                        token: token,
                        password: password,
                        password_confirmation: passwordConfirmation,
                        email: email,
                    })
                    .then(() => {
                        commit("hideLoading");
                        commit('logoutUser');
                        commit("showSuccessSnackbar", "Operação realizada com sucesso!")
                        resolve()
                    })
                    .catch(err => {
                        commit("hideLoading");
                        commit(
                            "showErrorSnackbar",
                            err.response.data.errors || err.response.data.message,
                        )
                        reject(err)
                    });
            })
        },
        recoverPassword({ commit }, { email }) {
            return new Promise((resolve, reject) => {
                client
                    .methods
                    .post("/v1/forgot-password", {
                        email: email,
                    })
                    .then(() => {
                        commit("showSuccessSnackbar", "Enviamos um e-mail para continuar com a redefinição de senha!")
                        resolve()
                    })
                    .catch(err => {
                        commit(
                            "showErrorSnackbar",
                            err.response.data.errors || err.response.data.message,
                        )
                        reject(err)
                    });
            })
        }
    },
    getters: {
    }
}
