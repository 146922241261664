const WAITING_PAYMENT = 1;
const PAID = 2;

const methods = {
    paid(rent) {
        return rent.payment_status.id == PAID;
    },
    paymentStatusesList() {
        return [
            {
                id: WAITING_PAYMENT,
                name: "A Receber",
            },
            {
                id: PAID,
                name: "Recebido",
            },
        ]
    }
}
export default {
    WAITING_PAYMENT,
    PAID,
    methods
};
