<template>
  <div>
    <v-chip :color="color" class="ma-2" small>
      {{ item.rent_state.description }}
    </v-chip>
  </div>
</template>

<script>
import status from "@/mixins/rent/status";

export default {
  props: ["item"],
  mixins: [status],
  computed: {
    color() {
      switch (this.item.rent_state.type) {
        case "to_place":
          return "blue lighten-4";
        case "expired":
          return "red lighten-4";
        case "to_withdraw":
          return "yellow lighten-4";
        case "canceled":
          return "gray lighten-4";
        case "withdrawn":
          return "gray lighten-4";
        case "ongoing":
          return "green lighten-4";
        case "due_today":
          return "orange lighten-4";
      }
    },
  },
};
</script>

<style>
</style>
