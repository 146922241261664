<template>
  <v-dialog value="true" persistent max-width="500px">
    <ValidationObserver v-slot="{ invalid, validated, handleSubmit }">
      <v-card>
        <v-progress-linear
          indeterminate
          color="primary"
          absolute
          :active="loading"
        ></v-progress-linear>
        <v-card-title>
          <span class="text-h5">Nova Locação</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <ValidationProvider
                  name="cliente"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <v-autocomplete
                    v-model="address"
                    :items="searchByClientNameItems"
                    :loading="isLoadingByClientName"
                    :search-input.sync="searchRentsByClientName"
                    small-chips
                    hide-details
                    return-object
                    itemid="client.id"
                    item-text="client.name"
                    item-value="client.id"
                    label="Nome do cliente"
                    no-data-text="Nenhum dado encontrado"
                    :error-messages="errors"
                  >
                    <template v-slot:item="{ item }">
                      <v-list-item-content>
                        <v-list-item-title
                          v-text="item.client.name"
                        ></v-list-item-title>
                        <v-list-item-subtitle
                          v-text="parseAddress(item)"
                        ></v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                  </v-autocomplete>
                </ValidationProvider>
              </v-col>
              <v-col cols="6">
                <ValidationProvider
                  name="situação do pagamento"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <v-select
                    :items="paymentStatuses"
                    item-value="id"
                    item-text="name"
                    small-chips
                    label="Situação do pagamento"
                    :error-messages="errors"
                    v-model="paymentStatusId"
                  ></v-select>
                </ValidationProvider>
              </v-col>
              <v-col cols="6">
                <ValidationProvider
                  name="situação da locação"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <v-select
                    :items="rentStatuses"
                    item-value="id"
                    item-text="name"
                    small-chips
                    label="Situação da locação"
                    :error-messages="errors"
                    v-model="rentStatusId"
                  ></v-select>
                </ValidationProvider>
              </v-col>
              <v-col cols="12" v-if="rentDatesRequired">
                <v-menu
                  v-model="placingMenu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  max-width="290px"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <ValidationProvider
                      name="data de colocação"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <v-text-field
                        v-model="computedPlacingDateFormatted"
                        label="Data de colocação"
                        persistent-hint
                        v-bind="attrs"
                        v-on="on"
                        readonly
                        key="placing-date"
                        :error-messages="errors"
                      ></v-text-field>
                    </ValidationProvider>
                  </template>
                  <v-date-picker
                    v-model="placingDate"
                    no-title
                    @input="placingMenu = false"
                    locale="pt-br"
                    color="primary"
                    :max="maxPlacingDate"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" v-if="rentDatesRequired">
                <v-menu
                  v-model="devolutionMenu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  max-width="290px"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <ValidationProvider
                      name="previsão de retirada"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <v-text-field
                        v-model="computedDevolutionDueDateFormatted"
                        label="Previsão de retirada"
                        persistent-hint
                        key="devolution-due-date"
                        v-bind="attrs"
                        v-on="on"
                        readonly
                        :error-messages="errors"
                      ></v-text-field>
                    </ValidationProvider>
                  </template>
                  <v-date-picker
                    v-model="devolutionDueDate"
                    no-title
                    @input="devolutionMenu = false"
                    locale="pt-br"
                    :min="placingDate"
                    color="primary"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="6">
                <ValidationProvider
                  name="valor"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <vuetify-money
                    v-model="price"
                    label="Valor"
                    key="price"
                    v-bind:valueWhenIsEmpty="valueWhenIsEmpty"
                    v-bind:options="options"
                    :error-messages="errors"
                  />
                </ValidationProvider>
              </v-col>
              <v-col cols="6">
                <v-autocomplete
                  v-model="dumpsterId"
                  :items="dumpstersByAvailability"
                  small-chips
                  hide-details
                  itemid="id"
                  item-text="number"
                  item-value="id"
                  label="Caçamba"
                  no-data-text="Nenhum dado encontrado"
                  clearable
                >
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="$emit('close')">
            Fechar
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="handleSubmit(create)"
            :disabled="invalid || !validated"
          >
            Salvar
          </v-btn>
        </v-card-actions>
      </v-card>
    </ValidationObserver>
  </v-dialog>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import address from "@/mixins/rent/address";
import payment from "@/mixins/rent/payment";
import status from "@/mixins/rent/status";
import { mapGetters } from "vuex";
import moment from "moment-timezone";

export default {
  mixins: [address, payment, status],
  data() {
    return {
      loading: false,
      searchRentsByClientName: null,
      lastChangeByClientName: 0,
      isLoadingByClientName: false,
      delayByClientName: 300,
      address: null,
      searchByClientNameItems: [],
      paymentStatusId: null,
      dumpsterId: null,
      placingDate: null,
      devolutionDueDate: null,
      maxPlacingDate: moment().tz("America/Sao_Paulo").format("YYYY-MM-DD"),
      placingMenu: false,
      devolutionMenu: false,
      price: "",
      options: {
        locale: "pt-BR",
        length: 6,
        precision: 2,
      },
      paymentStatuses: this.paymentStatusesList(),
      rentStatuses: [
        {
          id: status.TO_PLACE,
          name: "A Colocar",
        },
        {
          id: status.PLACED,
          name: "Colocada",
        },
      ],
      rentStatusId: null,
      rentDatesRequired: false,
      valueWhenIsEmpty: null,
    };
  },
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  watch: {
    rentStatusId(val) {
      const placed = val == status.PLACED;
      this.rentDatesRequired = placed;

      if (!placed) {
        this.placingDate = null;
        this.devolutionDueDate = null;
      }
    },
    searchRentsByClientName(val) {
      if (val == null) return;
      if (val.length < 3) return;

      this.isLoadingByClientName = true;
      this.lastChangeByClientName = new Date().getTime();
      setTimeout(() => {
        if (
          new Date().getTime() >=
          this.lastChangeByClientName + this.delayByClientName
        ) {
          this.$store
            .dispatch("searchAddressesByClientName", { clientName: val })
            .then(() => {
              this.isLoadingByClientName = false;
              this.searchByClientNameItems =
                this.$store.getters.addressesByClientName;
            })
            .catch(() => {
              this.isLoadingByClientName = false;
            });
        }
      }, this.delayByClientName);
    },
  },
  computed: {
    ...mapGetters({
      dumpstersByAvailability: "dumpstersByAvailability",
    }),
    computedPlacingDateFormatted() {
      return this.formatDate(this.placingDate);
    },
    computedDevolutionDueDateFormatted() {
      return this.formatDate(this.devolutionDueDate);
    },
  },
  mounted() {
    this.$store.dispatch("searchDumpstersByAvailability");
  },
  methods: {
    formatDate(date) {
      if (date == null) return null;

      return moment(date, "YYYY-MM-DD").format("DD/MM/YYYY");
    },
    create() {
      this.loading = true;

      this.$store
        .dispatch("createRent", {
          clientId: this.address.client.id,
          postalCode: this.address.postal_code,
          streetName: this.address.street_name,
          streetNumber: this.address.street_number,
          secondaryAddress: this.address.secondary_address,
          district: this.address.district,
          city: this.address.city,
          stateAbbr: this.address.state_abbr,
          paymentStatusId: this.paymentStatusId,
          rentStatusId: this.rentStatusId,
          dumpsterId: this.dumpsterId,
          placingDate: this.placingDate,
          devolutionDueDate: this.devolutionDueDate,
          price: this.price,
        })
        .then(() => {
          this.loading = false;
          this.$emit("close");
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style>
</style>
