import { required, email, integer, numeric, min, length, confirmed } from "vee-validate/dist/rules";
import cpfCnpj from './cpf-cnpj-validator'
import { extend, localize } from "vee-validate";
import ptBR from 'vee-validate/dist/locale/pt_BR.json'

localize('pt_BR', ptBR)

extend("required", {
  ...required
});

extend("email", {
  ...email
});

extend("integer", {
  ...integer
});

extend("numeric", {
  ...numeric
});

extend("min", {
  ...min
});

extend("cpfCnpj", {
  ...cpfCnpj
});

extend("length", {
  ...length
});

extend("confirmed", {
  ...confirmed
});
