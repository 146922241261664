var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.dialogs.createClient)?_c('create-client',{on:{"close":function($event){_vm.dialogs.createClient = false}}}):_vm._e(),_c('v-data-table',{attrs:{"headers":_vm.headers,"header-props":_vm.headerProps,"items":_vm.clients,"page":_vm.page,"items-per-page":_vm.itemsPerPage,"hide-default-footer":"","show-expand":"","single-expand":"","item-key":"id","no-data-text":"Nenhum dado encontrado"},on:{"update:page":function($event){_vm.page=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('client-filter'),_c('v-toolbar',{attrs:{"flat":""}},[_c('v-btn',{attrs:{"color":"pink","fab":"","x-small":"","dark":""},on:{"click":function($event){_vm.dialogs.createClient = true}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-plus ")])],1)],1)]},proxy:true},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('client-expanded-info',{attrs:{"headers":headers,"item":item}})]}},{key:"item.address",fn:function(ref){
var item = ref.item;
return [_c('client-address',{attrs:{"item":item}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('client-menu',{attrs:{"item":item}})]}}])}),_c('div',{staticClass:"text-center"},[_c('v-divider'),_c('v-container',[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',[_c('v-container',{staticClass:"max-width"},[_c('v-pagination',{attrs:{"length":_vm.pageCount,"total-visible":7},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }