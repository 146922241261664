const TO_PLACE = 1
const PLACED = 2
const TO_WITHDRAW = 5
const WITHDRAWN = 6
const CANCELED = 7

const ONGOING = [
    TO_PLACE,
    PLACED,
    TO_WITHDRAW,
];

const COMPLETED = [
    WITHDRAWN,
    CANCELED,
];

const methods = {
    ongoing(rent) {
        return ONGOING.includes(rent.rent_status.id);
    },
    canceled(rent) {
        return rent.rent_status.id == CANCELED;
    },
    rentStatusesList() {
        return [
            {
                id: TO_PLACE,
                name: "A Colocar",
            },
            {
                id: PLACED,
                name: "Colocada",
            },
            {
                id: TO_WITHDRAW,
                name: "A Retirar",
            },
            {
                id: WITHDRAWN,
                name: "Retirada",
            },
            {
                id: CANCELED,
                name: "Cancelada",
            },
        ]
    }
}
export default {
    TO_PLACE,
    PLACED,
    TO_WITHDRAW,
    WITHDRAWN,
    CANCELED,
    methods
};
