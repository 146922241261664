<template>
  <list-dumpsters />
</template>

<script>
import ListDumpsters from "../components/dumpster/ListDumpsters";

export default {
  components: {
    ListDumpsters,
  },
};
</script>
