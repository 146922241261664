<template>
  <v-dialog value="true" persistent max-width="500px">
    <ValidationObserver v-slot="{ invalid, validated, handleSubmit }">
      <v-card>
        <v-progress-linear
          indeterminate
          color="primary"
          absolute
          :active="loading"
        ></v-progress-linear>
        <v-card-title>
          <span class="text-h5">Retirar Locação Nº {{ item.public_id }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-menu
                  v-model="menu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <ValidationProvider
                      name="data de retirada"
                      rules="required"
                      v-slot="{ errors, valid }"
                    >
                      <v-text-field
                        v-model="computedDateFormatted"
                        label="Data de retirada"
                        persistent-hint
                        prepend-icon="mdi-calendar"
                        v-bind="attrs"
                        v-on="on"
                        readonly
                        :error-messages="errors"
                        :success="valid"
                      ></v-text-field>
                    </ValidationProvider>
                  </template>
                  <v-date-picker
                    v-model="date"
                    no-title
                    @input="menu = false"
                    locale="pt-br"
                    :max="max"
                    color="primary"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="$emit('close')">
            Fechar
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="handleSubmit(withdraw)"
            :disabled="invalid || !validated"
          >
            Salvar
          </v-btn>
        </v-card-actions>
      </v-card>
    </ValidationObserver>
  </v-dialog>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import moment from "moment-timezone";

export default {
  props: ["item"],
  data() {
    return {
      max: moment().tz("America/Sao_Paulo").format("YYYY-MM-DD"),
      loading: false,
      date: null,
      menu: false,
    };
  },
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  computed: {
    computedDateFormatted() {
      return this.formatDate(this.date);
    },
  },
  methods: {
    formatDate(date) {
      if (date == null) return null;

      return moment(date, "YYYY-MM-DD").format("DD/MM/YYYY");
    },
    withdraw() {
      this.loading = true;

      this.$store
        .dispatch("withdrawRent", {
          rentId: this.item.id,
          devolutionDate: this.date,
        })
        .then(() => {
          this.loading = false;
          this.$emit("close");
        })
        .catch(() => {
          this.loading = false;
          this.$emit("close");
        });
    },
  },
};
</script>

<style>
</style>
