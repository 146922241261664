const methods = {
    parseAddress(item) {
        return item?.street_name
            ?.concat(item.street_number ? `, ${item.street_number}` : "")
            ?.concat(item.secondary_address ? ` - ${item.secondary_address}` : "")
            ?.concat(item.district ? ` - ${item.district}` : "")
            ?.concat(item.postal_code ? ` - ${item.postal_code}` : "")
            ?.concat(item.city ? ` - ${item.city}` : "")
            ?.concat(item.state_abbr ? ` - ${item.state_abbr}` : "");
    },
}
export default {
    methods
};
