<template>
  <div>
    <v-expansion-panels flat v-model="panel" tile>
      <v-expansion-panel>
        <v-expansion-panel-header color="grey lighten-3">
          <div>
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-icon dark color="grey darken-1" v-bind="attrs" v-on="on">
                  mdi-filter
                </v-icon>
              </template>
              <span>Filtrar clientes</span>
            </v-tooltip>
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-container fluid>
            <ValidationObserver ref="observer">
              <v-row>
                <v-col cols="12" md="6">
                  <v-autocomplete
                    v-model="clientByClientName"
                    @change="filter"
                    :items="searchByClientNameItems"
                    :loading="isLoadingByClientName"
                    :search-input.sync="searchClientsByClientName"
                    small-chips
                    clearable
                    hide-details
                    return-object
                    itemid="id"
                    item-text="name"
                    item-value="id"
                    label="Nome do cliente"
                    no-data-text="Nenhum dado encontrado"
                  >
                    <template v-slot:item="{ item }">
                      <v-list-item-content>
                        <v-list-item-title
                          v-text="item.name"
                        ></v-list-item-title>
                        <v-list-item-subtitle
                          v-text="formatAddress(item)"
                        ></v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" md="6">
                  <v-autocomplete
                    v-model="clientByStreetName"
                    @change="filter"
                    :items="searchByStreetNameItems"
                    :loading="isLoadingByStreetName"
                    :search-input.sync="searchClientsByStreetName"
                    small-chips
                    clearable
                    hide-details
                    return-object
                    itemid="street_name"
                    item-text="street_name"
                    item-value="street_name"
                    label="Endereço do cliente"
                    no-data-text="Nenhum dado encontrado"
                  >
                    <template v-slot:item="{ item }">
                      <v-list-item-content>
                        <v-list-item-title
                          v-text="item.street_name"
                        ></v-list-item-title>
                      </v-list-item-content>
                    </template>
                  </v-autocomplete>
                </v-col>
              </v-row>
            </ValidationObserver>
          </v-container>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import payment from "@/mixins/rent/payment";
import status from "@/mixins/rent/status";
import filters from "@/mixins/rent/filters";
import address from "@/mixins/rent/address";

export default {
  mixins: [payment, status, filters, address],
  data() {
    return {
      paymentStatusId: parseInt(this.$route.query.paymentStatusId) || null,
      clientByClientName: this.getClientByClientName(),
      clientByStreetName: this.getClientByStreetName(),
      searchByClientNameItems: this.getItemsByClientName(),
      searchByStreetNameItems: this.getItemsByStreetName(),
      singleExpand: true,
      panel: 0,
      isLoadingByClientName: false,
      isLoadingByStreetName: false,
      searchClientsByClientName: null,
      searchClientsByStreetName: null,
      lastChangeByClientName: 0,
      lastChangeByStreetName: 0,
      delayByClientName: 300,
      delayByStreetName: 300,
    };
  },
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  watch: {
    searchClientsByStreetName(val) {
      if (val == null) return;
      if (val.length < 3) return;

      this.isLoadingByStreetName = true;
      this.lastChangeByStreetName = new Date().getTime();
      setTimeout(() => {
        if (
          new Date().getTime() >=
          this.lastChangeByStreetName + this.delayByStreetName
        ) {
          this.$store
            .dispatch("searchAddressesByStreetName", { streetName: val })
            .then(() => {
              this.isLoadingByStreetName = false;
              this.searchByStreetNameItems =
                this.$store.getters.addressesByStreetName;
            })
            .catch(() => {
              this.isLoadingByStreetName = false;
            });
        }
      }, this.delayByClientName);
    },
    searchClientsByClientName(val) {
      if (val == null) return;
      if (val.length < 3) return;

      this.isLoadingByClientName = true;
      this.lastChangeByClientName = new Date().getTime();
      setTimeout(() => {
        if (
          new Date().getTime() >=
          this.lastChangeByClientName + this.delayByClientName
        ) {
          this.$store
            .dispatch("searchClientsByClientName", { clientName: val })
            .then(() => {
              this.isLoadingByClientName = false;
              this.searchByClientNameItems =
                this.$store.getters.clientsByClientName;
            })
            .catch(() => {
              this.isLoadingByClientName = false;
            });
        }
      }, this.delayByClientName);
    },
  },
  methods: {
    formatAddress(item) {
      return this.parseAddress(item?.addresses?.data[0]);
    },
    isSearchingByClientName() {
      return this.$route.query.clientName && this.$route.query.clientId;
    },
    parseSearchByClientNameFromRoute() {
      return {
        id: this.$route.query.clientId,
        name: this.$route.query.clientName,
      };
    },
    parseSearchByStreetNameFromRoute() {
      return {
        street_name: this.$route.query.streetName,
      };
    },
    getItemsByStreetName() {
      if (this.$route.query.streetName)
        return [this.parseSearchByStreetNameFromRoute()];

      return this.$store.getters.rentsByStreetName;
    },
    getItemsByClientName() {
      if (this.isSearchingByClientName())
        return [this.parseSearchByClientNameFromRoute()];

      return this.$store.getters.rentsByClientName;
    },
    getClientByStreetName() {
      if (this.$route.query.streetName)
        return this.parseSearchByStreetNameFromRoute();

      return null;
    },
    getClientByClientName() {
      if (this.isSearchingByClientName())
        return this.parseSearchByClientNameFromRoute();

      return null;
    },
    async filter() {
      const query = {
        ...this.$route.query,
        page: 1,
        clientId: this.clientByClientName?.id || undefined,
        clientName: this.clientByClientName?.name || undefined,
        streetName: this.clientByStreetName?.street_name || undefined,
      };

      this.$router.push({ query }, () => {});

      this.$store.dispatch("getClients", {
        pageNumber: 1,
        clientId: this.clientByClientName?.id || undefined,
        clientName: this.clientByClientName?.name || undefined,
        streetName: this.clientByStreetName?.street_name || null,
      });
    },
  },
};
</script>

<style>
</style>
