import instance from './instance';
import auth from '../auth'
import store from '../../store'
import router from '@/router'

export default {
    methods: {
        interceptors() {
            instance.interceptors.request.use((config) => {
                config.headers.Authorization = `Bearer ${auth.methods.getTokenWithExpiry(auth.TOKEN)}`;
                return config;
            }, (err) => {
                return Promise.reject(err);
            });

            instance.interceptors.response.use(function (response) {
                return response;
            }, function (err) {
                if (err.response) {
                    if (err.response.status === 401) {
                        store.commit('logoutUser');
                        router.push({ name: "Login" }, () => {});
                    }
                }

                return Promise.reject(err);
            });
        },
    },
};
