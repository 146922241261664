import client from '../../mixins/api/client'

function initialState() {
    return {
        dumpsters: [],
        pagination: {
            currentPage: 1,
            totalPages: 0,
            perPage: 10,
        },
        filter: {
            dumpsterNumber: [],
        },
        search: {
            dumpstersByAvailability: [],
        },
        dumpstersList: []
    }
}

function query(state) {
    return {
        pageNumber: state.pagination.currentPage,
        dumpsterId: state.filter.dumpsterNumber,
    }
}

export default {
    state: initialState,
    mutations: {
        setDumpstersSearchByAvailability(state, { response: { data: { data } } }) {
            state.search.dumpstersByAvailability = data;
        },
        setDumpstersList(state, { response: { data: { data } } }) {
            state.dumpstersList = data;
        },
        setDumpsters(state, { query: query, response: { data: { data, meta: { pagination } } } }) {
            state.dumpsters = data;
            state.pagination.currentPage = pagination.current_page;
            state.pagination.totalPages = pagination.total_pages;
            state.pagination.perPage = pagination.per_page;
            state.filter.dumpsterId = query.dumpsterId;
        },
        resetDumpsters(state) {
            const s = initialState()
            Object.keys(s).forEach(key => {
                state[key] = s[key]
            })
        },
    },
    actions: {
        getDumpsters({ commit, getters, state }, query) {
            commit("showLoading");

            const {
                pageNumber = 1,
                dumpsterNumber = []
            } = query;

            return new Promise((resolve, reject) => {
                client
                    .methods
                    .get(`/v1/companies/${getters.userCompanyId}/dumpsters`, {
                        params: {
                            include: state.include,
                            'page[size]': state.pagination.perPage,
                            'page[number]': pageNumber,
                            'filter[number]': [dumpsterNumber].flat().join(),
                            'sort': '-number'
                        }
                    })
                    .then(response => {
                        commit("hideLoading");
                        commit('setDumpsters', { response, query })
                        resolve()
                    })
                    .catch(err => {
                        commit("hideLoading");
                        commit("showErrorSnackbar", err)
                        reject(err)
                    });
            })
        },
        getDumpstersList({ commit, getters }) {
            return new Promise((resolve, reject) => {
                client
                    .methods
                    .get(`/v1/companies/${getters.userCompanyId}/dumpsters`, {
                        params: {
                            sort: 'number',
                        }
                    })
                    .then(response => {
                        commit('setDumpstersList', { response })
                        resolve()
                    })
                    .catch(err => {
                        commit("showErrorSnackbar", err)
                        reject(err)
                    });
            })
        },
        searchDumpstersByAvailability({ commit, getters }) {
            return new Promise((resolve, reject) => {
                client
                    .methods
                    .get(`/v1/companies/${getters.userCompanyId}/dumpsters/available`)
                    .then(response => {
                        commit('setDumpstersSearchByAvailability', { response })
                        resolve()
                    })
                    .catch(err => {
                        commit("showErrorSnackbar", err)
                        reject(err)
                    });
            })
        },
        createDumpster({ dispatch, getters, commit, state }, data) {
            return new Promise((resolve, reject) => {
                client
                    .methods
                    .post(`/v1/companies/${getters.userCompanyId}/dumpsters`,
                        {
                            volume: data.volume,
                            number: data.number,
                        })
                    .then(() => {
                        commit("showSuccessSnackbar", "Operação realizada com sucesso!")
                        dispatch("getDumpsters", query(state));
                        resolve()
                    })
                    .catch(err => {
                        commit("showErrorSnackbar", err.response.data.errors || err.response.data.message)
                        reject(err)
                    });
            })
        },
        updateDumpster({ dispatch, getters, commit, state }, data) {
            return new Promise((resolve, reject) => {
                client
                    .methods
                    .patch(`/v1/companies/${getters.userCompanyId}/dumpsters/${data.dumpsterId}`,
                        {
                            volume: data.volume,
                            number: data.number,
                        })
                    .then(() => {
                        commit("showSuccessSnackbar", "Operação realizada com sucesso!")
                        dispatch("getDumpsters", query(state));
                        resolve()
                    })
                    .catch(err => {
                        commit("showErrorSnackbar", err.response.data.errors || err.response.data.message)
                        reject(err)
                    });
            })
        },
    },
    getters: {
        dumpstersByAvailability: (state) => state.search.dumpstersByAvailability,
        dumpstersList: (state) => state.dumpstersList,
        allDumpsters: (state) => state.dumpsters,
        paginationPerPageDumpsters: (state) => state.pagination.perPage,
        paginationTotalPagesDumpsters: (state) => state.pagination.totalPages,
    }
}
