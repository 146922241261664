<template>
  <td :colspan="headers.length">
    <div v-if="item.cpf_cnpj">
      <v-list-item>
        <v-list-item-subtitle>
          <span class="text--primary">CPF/CNPJ:</span> {{ item.cpf_cnpj }}
        </v-list-item-subtitle>
      </v-list-item>
    </div>
    <div v-if="item.email">
      <v-divider></v-divider>
      <v-list-item>
        <v-list-item-subtitle>
          <span class="text--primary">E-mail:</span> {{ item.email }}
        </v-list-item-subtitle>
      </v-list-item>
    </div>
    <div v-if="item.phone">
      <v-divider></v-divider>
      <v-list-item>
        <v-list-item-subtitle>
          <span class="text--primary">Telefone:</span> {{ item.phone }}
        </v-list-item-subtitle>
      </v-list-item>
    </div>
    <div v-if="item.cell">
      <v-divider></v-divider>
      <v-list-item>
        <v-list-item-subtitle>
          <span class="text--primary">Celular:</span> {{ item.cell }}
        </v-list-item-subtitle>
      </v-list-item>
    </div>
  </td>
</template>

<script>
export default {
  props: ["item", "headers"],
};
</script>

<style>
</style>
